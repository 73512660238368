import { FC, useMemo } from 'react';
import { Table } from '@intility/bifrost-react';
import { AlsoGroupSubscription } from 'models/alsoConnectedSubs';
import RemoveConnection from '../removeConnection/RemoveConnection';

interface ShowExtendedRowContentProps {
  config: AlsoGroupSubscription;
  handleMarketMutate: () => void;
}

export const ShowExtendedRowContent: FC<ShowExtendedRowContentProps> = ({ config, handleMarketMutate }) => {
  return useMemo(() => {
    if (config.alsoGroupConnections.length > 0) {
      return (
        <Table noBorder style={{ background: 'var(--bfc-base-1)' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell align="left" style={{ color: 'var(--bfc-base-c-1)' }}>
                Azure AD Group
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>ID</Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Members</Table.HeaderCell>
              <Table.HeaderCell align="right"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {config.alsoGroupConnections.map((group) => (
              <Table.Row key={group.id}>
                <Table.Cell>{group.groupName}</Table.Cell>
                <Table.Cell>{group.id}</Table.Cell>
                <Table.Cell>{group.groupMemberCount}</Table.Cell>
                <Table.Cell align="right">
                  <RemoveConnection
                    groupId={group.id}
                    AlsoGroupSubscription={config}
                    handleMarketMutate={handleMarketMutate}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    }
    return null;
  }, [config, handleMarketMutate]);
};

export default ShowExtendedRowContent;
