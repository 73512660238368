import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

async function removeAdobe(groupId: string | undefined) {
  return authorizedFetch(BaseAPIEndpoint + `adobe/${groupId}`, {
    method: 'DELETE',
    headers: {
      accept: 'application/json',
    },
  });
}

export const useRemoveAdobeGroup = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const removeAdobeGroup = useCallback(async (groupId: string | undefined) => {
    setLoading(true);
    try {
      const response = await removeAdobe(groupId);
      setLoading(false);
      if (response) {
        return true;
      } else {
        setError(true);
      }
    } catch (errorObj) {
      console.log('Error removing adobe group', errorObj);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    removeAdobeGroup,
    loading,
    error,
  };
};
