import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Pagination, Skeleton, Table } from '@intility/bifrost-react';
import { UserLicenseState } from '../../../../models';
import { usePagination, useSorting } from '../../../../helpers/hooks';
import ReprocessLicense from '../reprocessLicense/ReprocessLicense';
import LicenseError from '../licenseError/LicenseError';
import { TableFeedBack } from 'components';
import formatDate from 'helpers/formatDate/FormatDate';

interface LicenseStateTableProps {
  userLicenseStates?: UserLicenseState[];
  loading?: boolean;
  error?: any;
}

const LicenseStateTable: FC<LicenseStateTableProps> = ({ userLicenseStates, loading, error }) => {
  const navigate = useNavigate();
  const { getHeaderCellProps, sortedData } = useSorting<UserLicenseState>(userLicenseStates || []);
  const paginationData = usePagination<UserLicenseState>(sortedData, 500);

  const handleRedirectUserDetails = useCallback(
    (userLicenseError: UserLicenseState) => {
      const urlEncoded = {
        tenant: {
          tenantName: userLicenseError.tenantName,
          tenantId: userLicenseError.tenantId,
        },
        user: userLicenseError.upn,
      };
      navigate(`/user-details`, { state: urlEncoded });
    },
    [navigate],
  );

  const handleRedirectTenantCard = useCallback(
    (userLicenseError) => {
      navigate(`../tenant/${userLicenseError.tenantId}`);
    },
    [navigate],
  );

  const showUserLicenseErrors = useMemo(() => {
    if (paginationData.data.length > 0 && !loading) {
      return paginationData.data.map((userLicenseError, key) => {
        return (
          <Table.Row key={key}>
            <Table.Cell>
              <Button onClick={() => handleRedirectTenantCard(userLicenseError)} small title="Go to tenant card">
                {userLicenseError.tenantName}
              </Button>
            </Table.Cell>
            <Table.Cell>{userLicenseError.skuName}</Table.Cell>
            <Table.Cell>
              <Button onClick={() => handleRedirectUserDetails(userLicenseError)} small title="See assigned licenses">
                {userLicenseError.upn}
              </Button>
            </Table.Cell>
            <Table.Cell>{formatDate(userLicenseError.lastUpdatedTime, 'no-NB', true)}</Table.Cell>
            <LicenseError error={userLicenseError.errorMessage} />
            <Table.Cell>
              <ReprocessLicense userId={userLicenseError.userId} tenantId={userLicenseError.tenantId} />
            </Table.Cell>
          </Table.Row>
        );
      });
    }
    if (!loading) {
      return (
        <Table.Cell colSpan={9}>
          <TableFeedBack
            error={error}
            emptyMessage={'No assignment errors was found'}
            unfilteredData={userLicenseStates}
            filteredData={paginationData.data}
          />
        </Table.Cell>
      );
    } else {
      return (
        <Skeleton repeat={2}>
          <Table.Row>
            <Skeleton repeat={6}>
              <Table.Cell>
                <Skeleton.Text />
              </Table.Cell>
            </Skeleton>
          </Table.Row>
        </Skeleton>
      );
    }
  }, [error, handleRedirectTenantCard, handleRedirectUserDetails, loading, paginationData.data, userLicenseStates]);

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell {...getHeaderCellProps('tenantName')}>Tenant</Table.HeaderCell>
            <Table.HeaderCell {...getHeaderCellProps('skuName')}>Subscription</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell {...getHeaderCellProps('lastUpdatedTime')}>Last run</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{showUserLicenseErrors}</Table.Body>
      </Table>
      {paginationData.data.length >= 1 && paginationData.numberOfPages > 1 && (
        <Pagination
          displayPages={7}
          style={{ margin: '24px' }}
          totalPages={paginationData.numberOfPages}
          currentPage={paginationData.currentPage}
          onChange={paginationData.paginate}
        />
      )}
    </>
  );
};
export default LicenseStateTable;
