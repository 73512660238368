import React, { FC, useCallback } from 'react';
import { Accordion, Badge, Button, useFloatingMessage } from '@intility/bifrost-react';
import styles from './ManageSubsFormDrawerContent.module.css';
import { OptionType, SubscriptionConnection } from 'models';
import { Group } from '@microsoft/microsoft-graph-types';
import { usePostGroupConfigurations, useRemoveExtSubscription } from 'helpers/hooks';
import { useState, useMemo, useEffect } from 'react';
import SpinnerButton from 'components/common/spinnerButton/SpinnerButton';
import { TranslateStatuscode } from './helpers/translateStatuscode';
import { MSProps } from 'models/TenantConfigProps';
import ReminderSettings from './components/ReminderSettings';
import SubscriptionStatus from './components/SubscriptionStatus';
import RemoveDocSub from './components/RemoveDocSub';
import ConnectSubscriptionTab from './components/ConnectSubscriptionTab';
import SubscriptionDetailsTab from './components/SubscriptionDetailsTab';
import SubscriptionStatusHandler from './helpers/SubscriptionStatusHandler';
import MarkAsHandled from './components/MarkAsHandled';
import RequestNewGroup from './components/RequestNewGroup';
import {
  RequestConnectSubscription,
  RequestHandleChange,
  RequestReminderchange,
  RequestSubscriptionDetails,
} from './helpers/formHandlers';

interface ManageDrawerFormProps {
  selectedSubscription: any;
  tenantId: string;
  groups: Group[];
  setShowManageDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  usage?: 'connected' | 'unconnected' | 'documented';
  handleMSMutate: () => void;
  MSProps: MSProps;
}

const ManageSubsDrawer: FC<ManageDrawerFormProps> = React.memo(
  ({ selectedSubscription, tenantId, setShowManageDrawer, usage, MSProps }) => {
    const subscription: SubscriptionConnection = selectedSubscription;
    const { groups, handleMSMutate } = MSProps;
    const [groupError, setGroupError] = useState<boolean>(false);
    const [group, setGroup] = useState<OptionType | null>(null);
    const [groupBasedLicensing, setGroupBasedLicensing] = useState<boolean>(true);
    const [selectedButton, setSelectedButton] = useState('Sub');
    const { loading, createGroupConfig } = usePostGroupConfigurations();
    const [subscriptionValue, setSubscriptionValue] = useState<string>(subscription?.name || '');
    const [subscriptionDetailsChanged, setSubscriptionDetailsChanged] = useState(false);
    const [connectSubscriptionChanged, setConnectSubscriptionChanged] = useState(false);
    const [handledChanged, setHandledChanged] = useState<boolean>(false);
    const [handled, setHandled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reminderCheckBox, setReminderCheckBox] = useState<boolean>(false);
    const [reminderQuantity, setReminderQuantity] = useState<number>(0);
    const [reminderChanged, setReminderChanged] = useState<boolean>(false);
    const [autoRenewal, setAutoRenewal] = useState<boolean>(selectedSubscription.autoRenewEnabled || false);
    const [quantity, setQuantity] = useState<number>(subscription.quantity || 0);
    const [modalOpen, setModalOpen] = useState(false);
    const [licenseServiceId, setLicenseServiceId] = useState<string>(subscription.licenseServiceId || '');
    const { removeExtSubscription } = useRemoveExtSubscription();
    const { showFloatingMessage } = useFloatingMessage();
    const [SubscriptionModal, setSubScriptionModal] = useState(false);
    const [selectedSubscriptionStatus, setSelectedSubscriptionStatus] = useState<string>('');
    const [createGroupChanged, setCreateGroupChanged] = useState<boolean>(false);

    const handleRemoveExternal = useCallback(() => {
      removeExtSubscription(licenseServiceId).then(() => {
        setModalOpen(false);
        showFloatingMessage('Documented Sub deleted', { state: 'warning' });
        showFloatingMessage('Documented Sub deleted', { state: 'warning' });
        setShowManageDrawer(false);
        handleMSMutate();
      });
    }, [licenseServiceId, handleMSMutate, setShowManageDrawer, removeExtSubscription, showFloatingMessage]);

    const crementHandler = (type: 'plus' | 'minus') => {
      if (type === 'plus' && quantity !== undefined) {
        setQuantity(quantity + 1);
      } else if (type === 'minus' && quantity !== undefined && quantity > 1) {
        setQuantity(quantity - 1);
      }
    };

    useEffect(() => {
      if (selectedSubscription) {
        setReminderCheckBox(selectedSubscription.reminderQuantity > 0);
        setReminderQuantity(selectedSubscription.reminderQuantity || 0);
        setQuantity(selectedSubscription.quantity || 1);
        setLicenseServiceId(selectedSubscription.licenseServiceId || '');
        setAutoRenewal(selectedSubscription.autoRenewEnabled || false);
      }
    }, [selectedSubscription]);

    useEffect(() => {
      if (subscription.status === 'handled') {
        setHandled(true);
      } else {
        setHandled(false);
      }
    }, [subscription.status]);

    useEffect(() => {
      if (reminderChanged && !reminderCheckBox) {
        setReminderQuantity(0);
      }
    }, [reminderChanged, reminderCheckBox]);

    useEffect(() => {
      setSubscriptionValue(subscription?.name || '');
    }, [subscription]);

    const handleCancel = () => {
      setReminderCheckBox(false);
      setQuantity(subscription.quantity);
      setGroup(null);
      setAutoRenewal(selectedSubscription.autoRenewEnabled);
      setGroupBasedLicensing(true);
      setShowManageDrawer(false);
      setSelectedSubscriptionStatus('');
      setIsLoading(false);
      setSubscriptionDetailsChanged(false);
      setConnectSubscriptionChanged(false);
      setReminderChanged(false);
      setHandledChanged(false);
      setCreateGroupChanged(false);
    };

    const groupOptions = useMemo(() => {
      return groups.map((groupItem) => ({
        value: groupItem.id || '',
        label: groupItem.displayName || '',
      }));
    }, [groups]);

    const SubscriptionDetailsObject = {
      setIsLoading,
      tenantId,
      subscription,
      subscriptionValue,
      quantity,
      autoRenewal,
      setGroup,
      setShowManageDrawer,
      showFloatingMessage,
      handleMSMutate,
    };
    const ConnectSubscriptionObject = {
      setIsLoading,
      tenantId,
      subscription,
      group,
      selectedButton,
      groupBasedLicensing,
      setGroup,
      setShowManageDrawer,
      showFloatingMessage,
      handleMSMutate,
      createGroupConfig,
    };

    const HandleChangeObject = {
      setIsLoading,
      tenantId,
      subscription,
      handled,
      setShowManageDrawer,
      showFloatingMessage,
      handleMSMutate,
    };

    const ReminderChangeObject = {
      setIsLoading,
      setShowManageDrawer,
      showFloatingMessage,
      handleMSMutate,
      subscription,
      reminderQuantity,
    };

    const handleFormSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
      if (subscriptionDetailsChanged && subscription) {
        RequestSubscriptionDetails(SubscriptionDetailsObject);
      }
      if (connectSubscriptionChanged && group) {
        RequestConnectSubscription(ConnectSubscriptionObject);
      }
      if (handledChanged && subscription) {
        RequestHandleChange(HandleChangeObject);
      }

      if (reminderChanged) {
        RequestReminderchange(ReminderChangeObject);
      }

      setCreateGroupChanged(false);
      setReminderChanged(false);
      setHandledChanged(false);
      setSubscriptionDetailsChanged(false);
      setConnectSubscriptionChanged(false);
      handleCancel();
    };

    const { handleStatusChange } = SubscriptionStatusHandler({
      subscription,
      tenantId,
      setIsLoading,
      setSubScriptionModal,
      handleCancel,
      handleMSMutate,
    });

    return (
      <form onSubmit={handleFormSubmit}>
        <div className={styles.main}>
          <div className={styles.headerItems}>
            <p className={styles.selectedSubscrip + ' bf-medium'}>{subscription?.name || subscription.skuName}</p>
          </div>

          <Accordion variant="styled" className={styles.accordionStyle}>
            {(usage === 'connected' || usage === 'unconnected') && (
              <>
                <Accordion.Item
                  title={
                    <span className={styles.inlineStatusStyle + ' bf-strong bf-large'}>
                      {TranslateStatuscode(subscription.partnerStatus) === 'Active' && (
                        <Badge state="success">State: Active</Badge>
                      )}
                      {TranslateStatuscode(subscription.partnerStatus) === 'Suspended' && (
                        <Badge state="warning">State: Suspended</Badge>
                      )}
                      {TranslateStatuscode(subscription.partnerStatus) === 'Expired' && (
                        <Badge state="warning">State: Expired</Badge>
                      )}
                      {TranslateStatuscode(subscription.partnerStatus) === 'Deleted' && (
                        <Badge state="alert">State: Deleted</Badge>
                      )}
                      {TranslateStatuscode(subscription.partnerStatus) === 'Disabled' && (
                        <Badge state="alert">State: Disabled</Badge>
                      )}
                      {TranslateStatuscode(subscription.partnerStatus) === 'Unknown' && (
                        <Badge state="chill">State: Unknown</Badge>
                      )}
                    </span>
                  }
                  disabled={
                    loading ||
                    isLoading ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Deleted' ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Expired' ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Pending' ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Disabled' ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Unknown'
                  }
                >
                  <SubscriptionStatus
                    status={TranslateStatuscode(subscription.partnerStatus)}
                    subscription={subscription}
                    selectedSubscriptionStatus={selectedSubscriptionStatus}
                    SubscriptionModal={SubscriptionModal}
                    setSubScriptionModal={setSubScriptionModal}
                    setSelectedSubscriptionStatus={setSelectedSubscriptionStatus}
                    handleStatusChange={handleStatusChange}
                    handleCancel={handleCancel}
                    isLoading={isLoading}
                    loading={loading}
                  />
                </Accordion.Item>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Subscription details</span>}
                  disabled={loading || isLoading}
                >
                  <SubscriptionDetailsTab
                    subscriptionValue={subscriptionValue}
                    setSubscriptionValue={setSubscriptionValue}
                    setSubscriptionDetailsChanged={setSubscriptionDetailsChanged}
                    autoRenewal={autoRenewal}
                    setAutoRenewal={setAutoRenewal}
                    isLoading={isLoading}
                    loading={loading}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    crementHandler={crementHandler}
                  />
                </Accordion.Item>
              </>
            )}
            {usage === 'unconnected' && (
              <>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Mark as handled </span>}
                  disabled={loading || isLoading}
                >
                  <MarkAsHandled
                    handled={handled}
                    setHandled={setHandled}
                    setHandledChanged={setHandledChanged}
                    isLoading={isLoading}
                    loading={loading}
                  />
                </Accordion.Item>
              </>
            )}
            {(usage === 'connected' || usage === 'unconnected') && (
              <>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Connect subscription </span>}
                  disabled={loading || isLoading}
                >
                  <ConnectSubscriptionTab
                    group={group}
                    groupError={groupError}
                    groupOptions={groupOptions}
                    setGroup={setGroup}
                    setGroupError={setGroupError}
                    setConnectSubscriptionChanged={setConnectSubscriptionChanged}
                    groupBasedLicensing={groupBasedLicensing}
                    setGroupBasedLicensing={setGroupBasedLicensing}
                    selectedButton={selectedButton}
                    setSelectedButton={setSelectedButton}
                    isLoading={isLoading}
                    loading={loading}
                  />
                </Accordion.Item>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Create and connect group</span>}
                  disabled={loading || isLoading}
                >
                  <RequestNewGroup
                    tenantID={tenantId}
                    loading={loading}
                    setCreateGroupChanged={setCreateGroupChanged}
                    createGroupChanged={createGroupChanged}
                    handleCancel={handleCancel}
                    setIsLoading={setIsLoading}
                    setShowManageDrawer={setShowManageDrawer}
                    showFloatingMessage={showFloatingMessage}
                    tenantId={tenantId}
                    subscription={subscription}
                  />
                </Accordion.Item>
              </>
            )}

            {usage === 'documented' && (
              <>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Subscription details </span>}
                  disabled={loading || isLoading}
                >
                  {
                    <ReminderSettings
                      reminderCheckBox={reminderCheckBox}
                      setReminderCheckBox={setReminderCheckBox}
                      reminderQuantity={reminderQuantity}
                      setReminderQuantity={setReminderQuantity}
                      setReminderChanged={setReminderChanged}
                      isLoading={isLoading}
                      loading={loading}
                    />
                  }
                </Accordion.Item>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Delete documented Subscription</span>}
                  disabled={loading || isLoading}
                >
                  <RemoveDocSub
                    subscription={subscription}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleRemoveExternal={handleRemoveExternal}
                  />
                </Accordion.Item>
              </>
            )}
          </Accordion>
          <div className={styles.buttonGroup}>
            <Button variant="outline" type="button" onClick={() => handleCancel()} disabled={isLoading || loading}>
              Cancel
            </Button>
            <SpinnerButton isSubmitting={loading || isLoading} buttonText={'Save changes'} />
          </div>
        </div>
      </form>
    );
  },
);

ManageSubsDrawer.displayName = 'ManageSubsDrawer';

ManageSubsDrawer.displayName = 'ManageSubsDrawer';

export default ManageSubsDrawer;
