import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { OrderAvailability } from 'models/OrderAvailability';

export const fetchSubscriptionOrderAvailability = async (productId: string, skuId: string, countryCode: string) => {
  try {
    const response = await authorizedFetch<OrderAvailability[]>(
      `${BaseAPIEndpoint}Tenants/product/${productId}/sku/${skuId}/countryCode/${countryCode}`,
      {
        method: 'GET',
      },
    );
    if (!response.data) {
      throw new Error('Error fetching Availability');
    }
    return response.data;
  } catch (error) {
    console.log('Error fetching Availability', error);
    throw error;
  }
};
