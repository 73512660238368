import { useFloatingMessage } from '@intility/bifrost-react';
import { patchSubscriptionDetails } from 'api/fetchers/patchSubscriptionDetails';

const SubscriptionStatusHandler = ({
  subscription,
  tenantId,
  setIsLoading,
  setSubScriptionModal,
  handleCancel,
  handleMSMutate,
}) => {
  const { showFloatingMessage } = useFloatingMessage();

  const handleStatusChange = async (selectedSubscriptionStatus) => {
    if (subscription && selectedSubscriptionStatus) {
      setIsLoading(true);
      try {
        const subConfig = await patchSubscriptionDetails(
          tenantId,
          subscription.subscriptionId,
          undefined,
          undefined,
          undefined,
          selectedSubscriptionStatus,
        );
        if (subConfig) {
          setIsLoading(false);
          showFloatingMessage('Subscription status updated', { state: 'success' });
          setSubScriptionModal(false);
        }
      } catch (error) {
        console.log(error);
        showFloatingMessage('Failed to update subscription status' + JSON.stringify(error), { state: 'alert' });
      }
      setIsLoading(false);
      handleCancel();
      handleMSMutate();
    }
  };

  return { handleStatusChange };
};

export default SubscriptionStatusHandler;
