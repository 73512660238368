import { FC } from 'react';
import { faLink, faLinkSlash, faFileClipboard } from '@fortawesome/free-solid-svg-icons';
import styles from './ConfigSideNav.module.css';
import { Icon } from '@intility/bifrost-react';
const icons = [faLinkSlash, faLink, faFileClipboard];

interface ButtonOption {
  label: string;
  handler: () => void;
  count?: number;
  selected: boolean;
  isloading?: boolean;
}

interface Props {
  options: ButtonOption[];
}

const ConfigSideNav: FC<Props> = ({ options }) => {
  return (
    <div className={styles.navContainer}>
      {options.map((option, index) => (
        <button key={index} className={`${styles.navButton} bf-medium`} type="button" onClick={option.handler}>
          <Icon
            className={option.selected ? styles.navIcon : styles.navIconUnselected}
            icon={icons[index % icons.length]}
          />
          <span className={option.selected ? `${styles.selected} bf-medium bf-strong` : styles.unselected}>
            {option.label}
          </span>
          &nbsp;
          <span
            className={option.selected ? `${styles.numberStyle} bf-medium bf-strong` : styles.numberStyleUnselected}
          >
            {option.isloading ? <Icon.Spinner size={12} /> : option.count ? `(${option.count})` : '(0)'}
          </span>
        </button>
      ))}
    </div>
  );
};

export default ConfigSideNav;
