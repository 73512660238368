import { faBan } from '@fortawesome/free-solid-svg-icons';
import styles from './RemoveDocSub.module.css';
import { Modal, Inline, Button, Icon } from '@intility/bifrost-react';

const RemoveDocSub = ({ subscription, modalOpen, setModalOpen, handleRemoveExternal }) => {
  return (
    <>
      <p className="bf-medium">Delete the subscription from the documented list.</p>
      <Modal onRequestClose={() => setModalOpen(false)} header="Delete external subscription" isOpen={modalOpen}>
        <div>
          <p>{subscription?.name}</p>
          <p className="bf-strong">This external subscription will be deleted.</p>
        </div>

        <Inline className={styles.buttonGroup}>
          <Button state="alert" variant="filled" onClick={handleRemoveExternal}>
            Delete
          </Button>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
        </Inline>
      </Modal>
      <Button state="alert" variant="outline" title="Remove connection" small onClick={() => setModalOpen(true)}>
        <Icon color="red" icon={faBan} /> Delete subscription
      </Button>
    </>
  );
};

export default RemoveDocSub;
