import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { OrderProduct } from 'models/OrderProduct';

export const fetchSubscriptionOrderProducts = async (tenantId: string, subscriptionSegment: string) => {
  try {
    const response = await authorizedFetch<OrderProduct[]>(
      `${BaseAPIEndpoint}Tenants/${tenantId}/segment/${subscriptionSegment}/products`,
      {
        method: 'GET',
      },
    );
    if (!response.data) {
      throw new Error('Error fetching products');
    }
    return response.data;
  } catch (error) {
    console.log('Error fetching products', error);
    throw error;
  }
};
