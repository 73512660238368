import useSWR from 'swr';
import { SubscriptionConnection } from '../../models';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

/**
 * Fetch all active SubscriptionConnections for a customer.
 * @param tenantId
 */

export const useSubscriptionConnection = (tenantId: string | undefined) => {
  const fetchSubscriptionConnection = async () => {
    try {
      const response = await authorizedFetch<SubscriptionConnection[]>(
        `${BaseAPIEndpoint}tenants/${tenantId}/SubscriptionConnections`,
        {
          method: 'GET',
        },
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching SubscriptionConnection object', error);
      throw error;
    }
  };

  return useSWR('SubscriptionConnection', fetchSubscriptionConnection);
};
