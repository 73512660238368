import React, { FC } from 'react';
import styles from './ConfigureMicrosoft.module.css';
import ConfigSideNav from '../components/ConfigSideNav';
import ConfigSideDetails from '../components/ConfigSideDetails';
import UnConnected from './components/unconnected/UnConnected';
import Connected from './components/connected/Connected';
import Documented from './components/documented/Documented';
import { SubscriptionConnection } from 'models';
import { MSProps } from 'models/TenantConfigProps';

interface ConfigureMicrosoftProps {
  MSProps: MSProps;
  subscriptionConnections?: SubscriptionConnection;
}

export const ConfigureMicrosoft: FC<ConfigureMicrosoftProps> = React.memo(({ MSProps }) => {
  const {
    externalSubscriptions,
    subscriptionConnections,
    amountOfConnectedMS,
    amountOfUnhandledUnconnectedMS,
    amountOfHandledUnconnectedMS,
    amountOfDocumentedMS,
    isLoadingUnconnectedSubscriptions,
    isLoadingConnectedSubscriptions,
    isLoadingExternalSubscriptions,
  } = MSProps;
  const [showUnconnected, setShowUnconnected] = React.useState<boolean>(true);
  const [showConnected, setShowConnected] = React.useState<boolean>(false);
  const [showDocumented, setShowDocumented] = React.useState<boolean>(false);

  const handleUnconnectedClick = (): void => {
    setShowUnconnected(true);
    setShowConnected(false);
    setShowDocumented(false);
  };
  const handleConnectedClick = (): void => {
    setShowUnconnected(false);
    setShowConnected(true);
    setShowDocumented(false);
  };
  const handleDocumentedClick = (): void => {
    setShowUnconnected(false);
    setShowConnected(false);
    setShowDocumented(true);
  };

  const identifyAmounts = (subscriptionConnections, externalSubscriptions) => {
    const totals = {
      licensesAmount: 0,
      assignedAmount: 0,
      notAssignedAmount: 0,
      availableUnits: 0,
      groupsConnectedAmount: 0,
    };
    if (Array.isArray(subscriptionConnections)) {
      subscriptionConnections.forEach((subscription: SubscriptionConnection) => {
        totals.licensesAmount += subscription.quantity;
        totals.assignedAmount += subscription.assigned;
        totals.groupsConnectedAmount += subscription.groupConfigurations
          .map((group) => group.memberCount)
          .reduce((a, b) => a + b, 0);
        totals.notAssignedAmount += subscription.quantity - subscription.assigned;
      });
    }
    if (Array.isArray(externalSubscriptions)) {
      externalSubscriptions.forEach((subscription) => {
        totals.availableUnits += subscription.availableUnits;
      });
    }
    return totals;
  };

  const { licensesAmount, assignedAmount, notAssignedAmount, availableUnits, groupsConnectedAmount } = identifyAmounts(
    subscriptionConnections,
    externalSubscriptions,
  );

  return (
    <div className={styles.tabsPage}>
      <div className={styles.pageContainerLeft}>
        <ConfigSideNav
          options={[
            {
              label: 'Unconnected',
              handler: handleUnconnectedClick,
              count: amountOfUnhandledUnconnectedMS,
              selected: showUnconnected,
              isloading: isLoadingUnconnectedSubscriptions,
            },
            {
              label: 'Connected',
              handler: handleConnectedClick,
              count: amountOfConnectedMS,
              selected: showConnected,
              isloading: isLoadingConnectedSubscriptions,
            },
            {
              label: 'Documented',
              handler: handleDocumentedClick,
              count: amountOfDocumentedMS,
              selected: showDocumented,
              isloading: isLoadingExternalSubscriptions,
            },
          ]}
        />
        {showUnconnected && (
          <ConfigSideDetails
            howManyOptions={2}
            option1={'Unhandled Subs'}
            option1Value={amountOfUnhandledUnconnectedMS}
            option2="Handled Subs"
            option2Value={amountOfHandledUnconnectedMS}
          />
        )}
        {showConnected && (
          <ConfigSideDetails
            howManyOptions={4}
            option1={'Licenses'}
            option1Value={licensesAmount}
            option2="Assigned"
            option2Value={assignedAmount}
            option3="Not assigned"
            option3Value={notAssignedAmount}
            option4="Group members"
            option4Value={groupsConnectedAmount}
          />
        )}
        {showDocumented && (
          <ConfigSideDetails howManyOptions={1} option1={'Available Units'} option1Value={availableUnits} />
        )}
      </div>
      <div className={styles.pageContainerRight}>
        {showUnconnected && <UnConnected MSProps={MSProps} />}
        {showConnected && <Connected MSProps={MSProps} />}
        {showDocumented && <Documented MSProps={MSProps} />}
      </div>
    </div>
  );
});

ConfigureMicrosoft.displayName = 'ConfigureMicrosoft';

export default ConfigureMicrosoft;
