import { useEffect, useState } from 'react';
import { SetFieldValue } from 'react-hook-form';
import { setHours, parseISO, isSameDay, lastDayOfMonth, subDays, addMonths, addYears, compareAsc } from 'date-fns';
import formatDate from 'helpers/formatDate/FormatDate';
import { InnerSelectBadge } from '../InnerSelectBadge';
import { CleansedData } from './DateSelect';
import styles from './DateSelect.module.css';

interface UseDateSelectProps {
  cleansedData: CleansedData[];
  setValue: SetFieldValue<any>;
}

const useDateSelect = ({ cleansedData, setValue }: UseDateSelectProps) => {
  const formatOptionLabel = ({
    label,
    badge,
    description,
  }: {
    label: string;
    value: string;
    badge: () => JSX.Element;
    description: string;
  }) => (
    <div className={styles.optionLabel}>
      {label}
      {badge()}
      <div className={styles.optionDescription}>{description}</div>
    </div>
  );

  const options = cleansedData
    .map((data) => {
      let description = '';
      let now = new Date();
      now = setHours(now, 12);

      if (isSameDay(parseISO(data.date), lastDayOfMonth(now))) {
        description = 'Last day of this month';
      } else if (isSameDay(parseISO(data.date), subDays(addMonths(now, 1), 1))) {
        description = 'One month (renewal date is minus one day)';
      } else if (isSameDay(parseISO(data.date), lastDayOfMonth(addMonths(now, 11)))) {
        description = 'Last day of last month but in one year';
      } else if (isSameDay(parseISO(data.date), lastDayOfMonth(addMonths(now, 35)))) {
        description = 'Last day of last month but in three years';
      } else if (isSameDay(parseISO(data.date), subDays(addYears(now, 1), 1))) {
        description = 'One year (renewal date is minus one day)';
      } else if (isSameDay(parseISO(data.date), subDays(addYears(now, 3), 1))) {
        description = 'Three years (renewal date is minus one day)';
      }

      return {
        label: `${formatDate(data.date)}`,
        value: data.date,
        badge: () => InnerSelectBadge(data),
        description: description,
        licenseLength: data.license.length,
        dateObj: parseISO(data.date),
      };
    })
    .sort((a, b) => {
      if (a.licenseLength === 0 && b.licenseLength !== 0) {
        return -1;
      } else if (a.licenseLength !== 0 && b.licenseLength === 0) {
        return 1;
      }
      if (a.description && !b.description) {
        return -1;
      } else if (!a.description && b.description) {
        return 1;
      }
      return compareAsc(a.dateObj, b.dateObj);
    });

  const defaultValue = options.length > 0 ? options[0].value : null;
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    if (selected) {
      setValue('date', selected, { shouldValidate: true });
    }
  }, [selected, setValue]);

  return {
    options,
    selected,
    setSelected,
    formatOptionLabel,
  };
};

export default useDateSelect;
