import { deleteHandledMSSubscription } from 'api/fetchers/deleteHandledMSSubscription';
import { patchExternalSubscription } from 'api/fetchers/patchExternalSubscription';
import { patchSubscriptionDetails } from 'api/fetchers/patchSubscriptionDetails';
import { postHandledMSSubscription } from 'api/fetchers/postHandledMSSubscription';
import { postNewADGroup } from 'api/fetchers/postNewADGroup';
import { postNewEntraGroup } from 'api/fetchers/postNewEntraGroup';

export const RequestEntraGroup = async (RequestGroupObject) => {
  const {
    setIsLoading,
    setShowManageDrawer,
    showFloatingMessage,
    tenantId,
    subscription,
    selectedMethod,
    syncGroup,
    inputValue,
  } = RequestGroupObject;
  setIsLoading(true);
  try {
    await postNewEntraGroup(
      tenantId,
      subscription.subscriptionId,
      selectedMethod,
      syncGroup,
      inputValue + '.CloudUsers',
    );
  } catch (error) {
    console.log(error);
    showFloatingMessage('Failed to create Entra group' + JSON.stringify(error), { state: 'alert' });
  } finally {
    setShowManageDrawer(false);
    showFloatingMessage('Creating group - May take a few minutes', { state: 'success' });
    setIsLoading(false);
  }
};

export const RequestADGroup = async (RequestGroupObject) => {
  const {
    setIsLoading,
    setShowManageDrawer,
    showFloatingMessage,
    tenantId,
    subscription,
    selectedMethod,
    syncGroup,
    inputValue,
    selectedDirectory,
  } = RequestGroupObject;
  setIsLoading(true);
  try {
    await postNewADGroup(
      tenantId,
      subscription.subscriptionId,
      selectedDirectory.directoryId,
      selectedDirectory.companyId,
      selectedMethod,
      syncGroup,
      inputValue,
    );
  } catch (error) {
    console.log(error);
    showFloatingMessage('Failed to create AD group' + JSON.stringify(error), { state: 'alert' });
  } finally {
    setShowManageDrawer(false);
    showFloatingMessage('Creating group - estimated time to complete is around 30 minutes', { state: 'success' });
    setIsLoading(false);
  }
};

export const RequestSubscriptionDetails = async (SubscriptionDetailsObject) => {
  const {
    setIsLoading,
    tenantId,
    subscription,
    subscriptionValue,
    quantity,
    autoRenewal,
    setGroup,
    setShowManageDrawer,
    showFloatingMessage,
    handleMSMutate,
  } = SubscriptionDetailsObject;
  setIsLoading(true);
  const hasChanges = subscriptionValue || (quantity !== undefined && quantity > 1) || autoRenewal !== undefined;

  if (hasChanges) {
    try {
      const subConfig = await patchSubscriptionDetails(
        tenantId,
        subscription.subscriptionId,
        subscriptionValue,
        quantity,
        autoRenewal,
        undefined,
      );

      if (subConfig) {
        setGroup(null);
        setShowManageDrawer(false);
        showFloatingMessage('Subscription details updated', { state: 'success' });
      }
    } catch (error) {
      console.log(error);
      showFloatingMessage(`Failed to update subscription details: ${JSON.stringify(error)}`, { state: 'alert' });
    } finally {
      setIsLoading(false);
      handleMSMutate();
    }
  } else {
    console.log('No changes made to values in subscription details tab');
  }
};

export const RequestConnectSubscription = async (ConnectSubscriptionObject) => {
  const {
    setIsLoading,
    tenantId,
    subscription,
    group,
    selectedButton,
    groupBasedLicensing,
    setGroup,
    setShowManageDrawer,
    showFloatingMessage,
    handleMSMutate,
    createGroupConfig,
  } = ConnectSubscriptionObject;
  setIsLoading(true);
  try {
    const groupConfig = await createGroupConfig(
      tenantId,
      subscription.subscriptionId,
      group.value,
      selectedButton,
      groupBasedLicensing,
    );
    if (groupConfig) {
      setGroup(null);
      setShowManageDrawer(false);
      setIsLoading(false);
      showFloatingMessage('Subscription connected', { state: 'success' });
    }
  } catch (error) {
    console.log(error);
    showFloatingMessage('Failed to connect subscription' + JSON.stringify(error), { state: 'alert' });
  } finally {
    setIsLoading(false);
    handleMSMutate();
  }
};

export const RequestHandleChange = async (HandleChangeObject) => {
  const { setIsLoading, tenantId, subscription, handled, setShowManageDrawer, showFloatingMessage, handleMSMutate } =
    HandleChangeObject;
  if (handled === true) {
    setIsLoading(true);
    try {
      const config = await postHandledMSSubscription(tenantId, subscription.subscriptionId);
      if (config) {
        setShowManageDrawer(false);
        setIsLoading(false);
        showFloatingMessage('Subscription marked as handled', { state: 'success' });
      }
    } catch (error) {
      console.log(error);
      showFloatingMessage('Failed to mark subscription as handled' + JSON.stringify(error), { state: 'alert' });
    } finally {
      setIsLoading(false);
      handleMSMutate();
    }
  }
  if (handled === false) {
    setIsLoading(true);
    try {
      const config = await deleteHandledMSSubscription(tenantId, subscription.handledID);
      if (config) {
        setShowManageDrawer(false);
        setIsLoading(false);
        showFloatingMessage('Subscription marked as unhandled', { state: 'success' });
      }
    } catch (error) {
      console.log(error);
      showFloatingMessage('Failed to mark subscription as unhandled' + JSON.stringify(error), { state: 'alert' });
    } finally {
      setIsLoading(false);
      handleMSMutate();
    }
  }
};

export const RequestReminderchange = async (ReminderChangeObject) => {
  const { setIsLoading, setShowManageDrawer, showFloatingMessage, handleMSMutate, subscription, reminderQuantity } =
    ReminderChangeObject;

  setIsLoading(true);
  try {
    const subConfig = await patchExternalSubscription(subscription.licenseServiceId, reminderQuantity);
    if (subConfig) {
      setIsLoading(false);
      showFloatingMessage('Reminder set', { state: 'success' });
    }
  } catch (error) {
    console.log(error);
    showFloatingMessage('Failed to set reminder' + JSON.stringify(error), { state: 'alert' });
  } finally {
    setShowManageDrawer(false);
    setIsLoading(false);
    handleMSMutate();
  }
};
