import { FC, useState } from 'react';
import styles from './TenantHeader.module.css';
import { Button, Icon, Skeleton } from '@intility/bifrost-react';
import { faLinkSlash, faPlus } from '@fortawesome/free-solid-svg-icons';
import ConnectMPModal from './ConnectMPModal';
import DisconnectMPModal from './DisconnectMPModal';
import { AlsoConnection } from 'models/AlsoMarketPlace';

interface TenantHeaderProps {
  tenantDomain: string;
  msTenantName: string;
  msTenantID?: string;
  showConnectedMarketplaceModal: boolean;
  setShowConnectedMarketplaceModal: (showConnectMarketplace: boolean) => void;
  setOrderSubDrawer: (showOrderSubDrawer: boolean) => void;
  alsoConnectionID: AlsoConnection | undefined;
  alsoConnectionLoading: boolean;
  setAlsoConnectionLoading: (loading: boolean) => void;
}

export const TenantHeader: FC<TenantHeaderProps> = ({
  tenantDomain,
  msTenantName,
  msTenantID,
  showConnectedMarketplaceModal,
  setShowConnectedMarketplaceModal,
  setOrderSubDrawer,
  alsoConnectionID,
  alsoConnectionLoading,
  setAlsoConnectionLoading,
}) => {
  const [showDisconnectMPModal, setShowDisconnectMPModal] = useState(false);
  let alsoCompanyID: string;

  const openDisconnectModal = () => {
    setShowDisconnectMPModal(true);
  };

  if (alsoConnectionID && alsoConnectionID.alsoCustomerName) {
    alsoCompanyID = alsoConnectionID.alsoCustomerName;
  } else {
    alsoCompanyID = 'Not connected';
  }
  return (
    <>
      <div className={styles.headerContent}>
        <div className={styles.tenantAndButton}>
          <div>
            {msTenantName && <h1 className="bf-h1">{msTenantName}</h1>}
            {!msTenantName && (
              <h1 className={styles.skeletonHeader + ' bf-h1'}>
                <Skeleton.Text />
              </h1>
            )}
            {msTenantName && (
              <span className={styles.CompanyDomainLine}>
                <span className={styles.marketplaceConnect + ' bf-medium bf-strong'}>Domain:</span>
                <span>{tenantDomain}</span>
              </span>
            )}
          </div>
          <div className={styles.buttonPlacement}>
            <Button variant="outline" onClick={() => setOrderSubDrawer(true)}>
              <Icon icon={faPlus}></Icon> Order microsoft subscription
            </Button>
          </div>
        </div>
        {alsoConnectionID && (
          <span className={styles.CompanyIDLine}>
            <span className={styles.marketplaceConnect + ' bf-medium bf-strong'}>Marketplace:</span>
            <span className={styles.companyIDstyle + ' bf-medium bf-strong'}>{alsoCompanyID}</span>
            <span className={styles.CompanyID + ' bf-medium bf-strong'}>
              ID: {alsoConnectionID.alsoCompanyAccountId}
            </span>
            <button className={styles.button} onClick={openDisconnectModal}>
              <Icon icon={faLinkSlash} className={styles.linkStyleIcon}></Icon>
            </button>
          </span>
        )}
        {!alsoConnectionID && (
          <span className={styles.headerSpanMarket}>
            <p className={styles.marketplaceConnect + ' bf-medium bf-strong'}>{`Marketplace: ` + ''}</p>
            <p className={styles.linkStyleUnconnect + ' bf-medium bf-strong'}>{`${alsoCompanyID}`}</p>
          </span>
        )}
      </div>

      <ConnectMPModal
        showConnectedMarketplaceModal={showConnectedMarketplaceModal}
        setShowConnectedMarketplaceModal={setShowConnectedMarketplaceModal}
        msTenantName={msTenantName}
        msTenantID={msTenantID}
        setAlsoConnectionLoading={setAlsoConnectionLoading}
        alsoConnectionLoading={alsoConnectionLoading}
      />
      <DisconnectMPModal
        showDisconnectMPModal={showDisconnectMPModal}
        setShowDisconnectMPModal={setShowDisconnectMPModal}
        msTenantID={msTenantID}
        alsoCompanyAccountId={alsoConnectionID}
        setAlsoConnectionLoading={setAlsoConnectionLoading}
        alsoConnectionLoading={alsoConnectionLoading}
      />
    </>
  );
};

export default TenantHeader;
