import Select from '@intility/bifrost-react-select';
import styles from './ConnectSubscriptionTab.module.css';
import { Checkbox } from '@intility/bifrost-react';

const ConnectSubscriptionTab = ({
  group,
  groupError,
  groupOptions,
  setGroup,
  setGroupError,
  setConnectSubscriptionChanged,
  groupBasedLicensing,
  setGroupBasedLicensing,
  selectedButton,
  setSelectedButton,
  isLoading,
  loading,
}) => {
  return (
    <>
      <Select
        value={group}
        isClearable
        label="Azure AD group"
        placeholder="- Select group -"
        onBlur={() => setGroupError(false)}
        state={groupError ? 'alert' : 'default'}
        options={groupOptions}
        onChange={(groupOption) => {
          if (groupOption === null) {
            setGroup(null);
          } else {
            setGroup(groupOption);
          }
          setConnectSubscriptionChanged(true); // Track change
        }}
      />
      <Checkbox
        button
        style={{ marginTop: 16, marginBottom: 8 }}
        label="Use group-based licensing"
        checked={groupBasedLicensing}
        type="checkbox"
        onChange={() => {
          setGroupBasedLicensing((prevState) => !prevState);
          setConnectSubscriptionChanged(true); // Track change
        }}
        disabled={isLoading || loading}
      />
      <>
        <p className="bf-label" style={{ marginBottom: 0 }}>
          Order method
        </p>
        <div className={styles.radioButtons}>
          <Checkbox
            button
            type="radio"
            label="Sub"
            className={styles.Checkbox}
            checked={selectedButton === 'Sub'}
            onChange={() => {
              setSelectedButton('Sub');
              setConnectSubscriptionChanged(true); // Track change
            }}
            name="radioButtonDemo"
            key="Sub"
            disabled={isLoading || loading}
          />
          <Checkbox
            button
            type="radio"
            label="Sku"
            className={styles.Checkbox}
            checked={selectedButton === 'Sku'}
            onChange={() => {
              setSelectedButton('Sku');
              setConnectSubscriptionChanged(true); // Track change
            }}
            name="radioButtonDemo"
            key="Sku"
            disabled={isLoading || loading}
          />
        </div>
      </>
    </>
  );
};

export default ConnectSubscriptionTab;
