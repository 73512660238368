import { Badge, Button, Checkbox, Input } from '@intility/bifrost-react';
import styles from './RequestNewGroup.module.css';
import { useEffect, useState } from 'react';
import { useGetDirectories } from 'helpers/hooks';
import { checkIfGroupNameExist } from 'api/fetchers/checkIfGroupExist';
import { RequestADGroup, RequestEntraGroup } from '../helpers/formHandlers';
import { Directory } from 'models';

const RequestNewGroup = ({
  tenantID,
  loading,
  setCreateGroupChanged,
  createGroupChanged,
  setIsLoading,
  setShowManageDrawer,
  showFloatingMessage,
  tenantId,
  subscription,
  handleCancel,
}) => {
  const [selectedDirectory, setSelectedDirectory] = useState<Directory>();
  const [selectedIDP, setSelectedIDP] = useState<string>('AD');
  const [selectedMethod, setSelectedMethod] = useState<string>('sub');
  const [syncGroup, setSyncGroup] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [nameValidation, setNameValidation] = useState<string | null>(null);

  const { directories, error, isLoading } = useGetDirectories(tenantID);
  const RequestGroupObject = {
    setIsLoading,
    setShowManageDrawer,
    showFloatingMessage,
    tenantId,
    subscription,
    selectedMethod,
    syncGroup,
    inputValue,
    selectedDirectory,
  };

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (directories?.length) {
      setSelectedDirectory(directories[0]);
    }
  }, [directories]);

  useEffect(() => {
    const prefix = selectedDirectory ? `${selectedDirectory.directoryName}.App.365` : '';
    setInputValue(prefix);
  }, [selectedDirectory]);

  const handleDirectoryChange = (directory) => {
    setSelectedDirectory(directory);
  };

  const handleIDPChange = (idp) => {
    setSelectedIDP(idp);
  };

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
  };

  const handleSyncChange = () => {
    setSyncGroup(!syncGroup);
  };

  const handleInputChange = (e) => {
    setNameValidation(null);
    const input = e.target.value;
    const regex = /^[0-9a-zA-Z.\-@_]+$/;
    if (!regex.test(input)) {
      setNameValidation('Only letters, numbers, (.), (-), (_), and (@) are allowed.');
    } else {
      setInputValue(input);
      setCreateGroupChanged(true);
    }
    setInputValue(input);
  };

  const HandleClick = () => {
    let groupName;
    if (createGroupChanged && selectedIDP === 'Entra') {
      groupName = RequestGroupObject.inputValue + '.CloudUsers';
    } else {
      groupName = RequestGroupObject.inputValue;
    }
    checkIfGroupNameExist(RequestGroupObject.tenantId, groupName).then((alreadyExist) => {
      if (alreadyExist === null) {
        setNameValidation('An error occured, failed to create group');
      } else if (alreadyExist === true) {
        setNameValidation('GroupName does already exist.');
      } else {
        if (createGroupChanged && selectedIDP === 'Entra') {
          RequestEntraGroup(RequestGroupObject);
        }
        if (createGroupChanged && selectedIDP === 'AD') {
          RequestADGroup(RequestGroupObject);
        }
        HandleDiscardClick();
      }
    });
  };

  const HandleDiscardClick = () => {
    setCreateGroupChanged(false);
    setSelectedIDP('AD');
    setSelectedDirectory(directories ? directories[0] : undefined);
    setSelectedMethod('sub');
    setSyncGroup(false);
    const prefix = selectedDirectory ? `${selectedDirectory.directoryName}.App.365` : '';
    setInputValue(prefix);
    setNameValidation(null);
    handleCancel();
  };
  return (
    <>
      <div className={styles.BaseInputLayout}>
        <p className="bf-p bf-large">AD or Entra</p>
        <Checkbox
          className={styles.checkbox}
          type="radio"
          label="AD"
          checked={selectedIDP === 'AD'}
          onChange={() => handleIDPChange('AD')}
          name="IDP"
          disabled={isLoading || loading}
        />
        <Checkbox
          className={styles.checkbox}
          type="radio"
          label="Entra"
          checked={selectedIDP === 'Entra'}
          onChange={() => handleIDPChange('Entra')}
          name="IDP"
          disabled={isLoading || loading}
        />
      </div>
      <div className={styles.BaseInputLayout}>
        <p className="bf-p bf-large">AD Directory</p>
        {Array.isArray(directories) &&
          directories.map((directory) => (
            <Checkbox
              className={styles.checkbox}
              type="radio"
              key={directory.directoryId}
              label={directory.directoryName + (directory.ouDescription ? ` - ${directory.ouDescription}` : '')}
              checked={selectedDirectory?.directoryId === directory.directoryId}
              onChange={() => handleDirectoryChange(directory)}
              name="DirectoryRadio"
              disabled={isLoading || loading || selectedIDP === 'Entra'}
            />
          ))}
      </div>
      <div className={styles.BaseInputLayout}>
        <p className="bf-p bf-large">Order Method</p>
        <Checkbox
          className={styles.checkbox}
          type="radio"
          label="sub"
          checked={selectedMethod === 'sub'}
          onChange={() => handleMethodChange('sub')}
          name="method"
          disabled={isLoading || loading}
        />
        <Checkbox
          className={styles.checkbox}
          type="radio"
          label="sku"
          checked={selectedMethod === 'sku'}
          onChange={() => handleMethodChange('sku')}
          name="method"
          disabled={isLoading || loading}
        />
      </div>
      <div className={styles.BaseInputLayout}>
        <p className="bf-p bf-large">Connect group to sync</p>
        <Badge className={styles.MarginAddition} state="warning">
          NB: add-on licenses should NOT have sync active
        </Badge>
        <Checkbox
          className={styles.checkbox}
          checked={syncGroup}
          onChange={handleSyncChange}
          name="Sync"
          label={'Sync'}
          disabled={isLoading || loading}
        />
      </div>
      <div className={styles.BaseInputLayout}>
        <p className="bf-p bf-large">Group name</p>
        <Badge className={styles.MarginAddition} state="default">
          Verify prefixed name before proceeding
        </Badge>
        {selectedIDP === 'AD' && (
          <Input
            className={styles.MarginAddition}
            hideLabel
            label="Group name"
            disabled={isLoading || loading}
            onChange={handleInputChange}
            value={inputValue}
            state={nameValidation ? 'alert' : 'default'}
            feedback={nameValidation}
          />
        )}
        {selectedIDP === 'Entra' && (
          <div className={styles.EntraInput}>
            <Input
              className={styles.MarginAddition}
              hideLabel
              label="Group name"
              disabled={isLoading || loading}
              onChange={handleInputChange}
              value={inputValue}
              state={nameValidation ? 'alert' : 'default'}
              feedback={nameValidation}
            />
            <div className={styles.CloudUsersText}>.CloudUsers</div>
          </div>
        )}
      </div>
      <div className={styles.ButtonLayout}>
        <Button state="neutral" onClick={() => HandleDiscardClick()}>
          Discard
        </Button>
        <Button disabled={!createGroupChanged || nameValidation != null} onClick={() => HandleClick()}>
          Create and Connect Group
        </Button>
      </div>
    </>
  );
};

export default RequestNewGroup;
