import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { AdobeGroup } from '../../models';
import { authorizedFetch } from 'auth/authorizedFetch';

async function adobeGroupCall(adobeGroup: AdobeGroup): Promise<any> {
  return authorizedFetch(`${BaseAPIEndpoint}Adobe`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(adobeGroup),
  });
}

export const useAdobe = () => {
  const [adobeLoading, setAdobeLoading] = useState(false);
  const [adobeError, setAdobeError] = useState(false);

  const postAdobeGroup = useCallback(async (adobeGroup: AdobeGroup) => {
    setAdobeLoading(true);
    try {
      const response = await adobeGroupCall(adobeGroup);
      if (response.ok) {
        return true;
      } else {
        setAdobeError(true);
      }
    } catch (errorMessage) {
      console.log('Error creating Adobe group', errorMessage);
      setAdobeError(true);
    } finally {
      setAdobeLoading(false);
    }
  }, []);

  return {
    postAdobeGroup,
    adobeLoading,
    adobeError,
  };
};
