import useSWR from 'swr';
import { ExternalSubscriptionDto } from '../../models';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

/**
 * Fetch all external subscriptions for a customer.
 * @param tenantId
 */

export const useExternalSubscriptions = (tenantId: string | undefined) => {
  const fetchExternalSubscriptions = async () => {
    try {
      const response = await authorizedFetch<ExternalSubscriptionDto[]>(
        `${BaseAPIEndpoint}externalSubscriptions/${tenantId}`,
        {
          method: 'GET',
        },
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching ExternalSubscriptions object', error);
      throw error;
    }
  };

  return useSWR('ExternalSubscriptions', fetchExternalSubscriptions);
};
