import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { addMonths, addYears, isSameDay, parseISO, setHours, subDays } from 'date-fns';
import { OrderSubDetails } from 'models/OrderDetails';

export const orderNewSubscription = async (
  tenantId: string,
  availability: OrderSubDetails,
  quantity: number,
  date: string,
) => {
  const catalogItemId = availability.catalogItemId;
  const orderQuantity = quantity;
  const billingCycle = availability.billingCycle;
  const termDuration = availability.termDuration;
  const dateFromForm = date;
  let formattedTermEndDate;

  let today = new Date();
  today = setHours(today, 12);
  today = new Date(today.toISOString());

  formattedTermEndDate = dateFromForm;
  if (isSameDay(parseISO(dateFromForm), subDays(addMonths(today, 1), 1))) {
    formattedTermEndDate = null;
  } else if (isSameDay(parseISO(dateFromForm), subDays(addYears(today, 1), 1))) {
    formattedTermEndDate = null;
  } else if (isSameDay(parseISO(dateFromForm), subDays(addYears(today, 3), 1))) {
    formattedTermEndDate = null;
  }

  try {
    const response = await authorizedFetch<[]>(`${BaseAPIEndpoint}Tenants/${tenantId}/orderNewSubscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        catalogItemId: catalogItemId,
        quantity: orderQuantity,
        billingCycle: billingCycle,
        termDuration: termDuration,
        customTermEndDate: formattedTermEndDate,
      }),
    });
    if (!response.data) {
      throw new Error('Error submitting order');
    }
    return response;
  } catch (error) {
    console.log('Error submitting order', error);
    throw error;
  }
};
