import { FC } from 'react';
import styles from './DisconnectMPModal.module.css';
import { Modal, Button, useFloatingMessage } from '@intility/bifrost-react';
import { removeAlsoConnection } from 'api/fetchers/removeAlsoConnection';
import SpinnerButton from 'components/common/spinnerButton/SpinnerButton';

interface Props {
  showDisconnectMPModal: boolean;
  setShowDisconnectMPModal: (showConnectMarketplace: boolean) => void;
  alsoCompanyAccountId: any;
  msTenantID: string | undefined;
  alsoConnectionLoading: boolean;
  setAlsoConnectionLoading: (alsoConnectionLoading: boolean) => void;
}

const DisconnectMPModal: FC<Props> = ({
  setShowDisconnectMPModal,
  showDisconnectMPModal,
  alsoCompanyAccountId,
  msTenantID,
  alsoConnectionLoading,
  setAlsoConnectionLoading,
}) => {
  const LONG_TIMEOUT_DURATION = 1000;
  const SHORT_TIMEOUT_DURATION = 100;
  const { showFloatingMessage } = useFloatingMessage();
  const handleReloadClick = () => {
    window.location.reload();
  };

  const handleCancelClick = () => {
    setShowDisconnectMPModal(false);
    setTimeout(() => {
      handleReloadClick();
    }, SHORT_TIMEOUT_DURATION);
  };

  const handleDisconnectClick = async () => {
    setAlsoConnectionLoading(true);
    try {
      await removeAlsoConnection(alsoCompanyAccountId?.alsoCompanyAccountId, msTenantID);
    } catch (error: any) {
      showFloatingMessage('Failed to remove connection ' + error.data, { state: 'alert' });

      console.error('Failed to disconnect:', error);
    } finally {
      setTimeout(() => {
        setShowDisconnectMPModal(false);
        setAlsoConnectionLoading(false);
      }, LONG_TIMEOUT_DURATION);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await handleDisconnectClick();
    handleReloadClick();
  };

  return (
    <Modal
      className={styles.modal}
      onRequestClose={handleCancelClick}
      isOpen={showDisconnectMPModal}
      header="Disconnect marketplace connection"
    >
      <form onSubmit={handleSubmit}>
        <p>
          Currently connected: <span className="bf-medium bf-strong">{alsoCompanyAccountId?.alsoCustomerName}</span>
        </p>
        <div className={styles.modalButtons}>
          <SpinnerButton isSubmitting={alsoConnectionLoading} buttonText="Disconnect" state="alert" />
          <Button type="button" onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DisconnectMPModal;
