import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './SubscriptionDetailsTab.module.css';
import { Input, Checkbox, Icon } from '@intility/bifrost-react';

const SubscriptionDetailsTab = ({
  subscriptionValue,
  setSubscriptionValue,
  setSubscriptionDetailsChanged,
  autoRenewal,
  setAutoRenewal,
  isLoading,
  loading,
  quantity,
  setQuantity,
  crementHandler,
}) => {
  return (
    <>
      <Input
        label={'Subscription'}
        value={subscriptionValue || ''}
        onChange={(e) => {
          setSubscriptionValue(e.target.value);
          setSubscriptionDetailsChanged(true);
        }}
      />
      <Checkbox
        style={{ marginTop: 16, marginBottom: 8, textAlign: 'center' }}
        label="Auto renewal"
        checked={autoRenewal}
        type="switch"
        onChange={() => {
          setAutoRenewal((prevState) => !prevState);
          setSubscriptionDetailsChanged(true);
        }}
        disabled={isLoading || loading}
      />
      <div className={styles.inputContainer}>
        <p className="bf-medium bf-strong" style={{ marginRight: 38, marginBottom: 0 }}>
          Quantity
        </p>
        <div className={styles.inputButtonContainer}>
          <button
            disabled={quantity === undefined || isLoading || loading}
            type="button"
            className={quantity !== undefined && quantity > 1 ? styles.crementButtons : styles.crementButtonsDisabled}
            onClick={() => {
              crementHandler('minus');
              setSubscriptionDetailsChanged(true);
            }}
          >
            <Icon icon={faMinus}></Icon>
          </button>
          <Input
            disabled={quantity === undefined}
            className={styles.quantityInput}
            label={'Quantity'}
            hideLabel
            type="number"
            value={quantity !== undefined ? quantity : undefined}
            onChange={(e) => {
              setQuantity(Number(e.target.value));
              setSubscriptionDetailsChanged(true);
            }}
          />
          <button
            type="button"
            className={styles.crementButtons}
            onClick={() => {
              crementHandler('plus');
              setSubscriptionDetailsChanged(true);
            }}
            disabled={quantity === undefined || isLoading || loading}
          >
            <Icon icon={faPlus}></Icon>
          </button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetailsTab;
