import React, { FC, useMemo, useState } from 'react';
import styles from './Documented.module.css';
import SearchBarFilter from '../../../components/SearchBarFilter';
import { Button, Drawer, Skeleton, Table } from '@intility/bifrost-react';
import ShowExternalSubscriptions from './ShowExternalSubscriptions';
import ExternalSubscriptionForm from './addExternalSubscriptionForm/AddExternalSubscriptionForm';
import { useSorting } from 'helpers/hooks';
import { ExternalSubscriptionDto } from 'models';
import ManageSubsDrawer from '../manageSubsForm/ManageSubsFormDrawerContent';
import RemoveAdobe from './removeAdobe/RemoveAdobe';
import { MSProps } from 'models/TenantConfigProps';

interface DocumentedProps {
  MSProps: MSProps;
}

const Documented: FC<DocumentedProps> = React.memo(({ MSProps }) => {
  const {
    externalSubscriptions,
    renderGroupName,
    isLoadingExternalSubscriptions,
    error,
    assuredTenantId,
    groups,
    externalSubscription,
    handleMSMutate,
    adobeGroups,
    isLoadingAdobe,
  } = MSProps;
  //Passing jsx as a variable to the searchbarfilter component
  const NameElement = (
    <>
      <span className={styles.defaultName}>Documented subscriptions </span>
    </>
  );
  const HeaderContent = (
    <Button small variant="filled" onClick={() => setExternalVendorDrawer(true)}>
      + Add document subscription
    </Button>
  );

  const [externalVendorDrawer, setExternalVendorDrawer] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const { getHeaderCellProps, sortedData } = useSorting<ExternalSubscriptionDto>(filteredData || []);
  const [showConnectedManageDrawer, setShowConnectedManageDrawer] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<any>({});

  const showAdobe = useMemo(() => {
    if (adobeGroups) {
      return adobeGroups.map((adobeGroup) => {
        return (
          <Table.Row key={adobeGroup.groupId} style={{ background: 'var(--bfc-base)', color: 'var(--bfc-base-c-2)' }}>
            <Table.Cell>Adobe</Table.Cell>
            <Table.Cell>Adobe</Table.Cell>
            <Table.Cell>N/A</Table.Cell>
            <Table.Cell>N/A</Table.Cell>
            <Table.Cell>{adobeGroup.groupName}</Table.Cell>
            <Table.Cell>N/A</Table.Cell>
            <Table.Cell>N/A</Table.Cell>
            <Table.Cell>N/A</Table.Cell>
            <Table.Cell align="center">
              <RemoveAdobe groupId={adobeGroup.groupId} />
            </Table.Cell>
          </Table.Row>
        );
      });
    }
    if (isLoadingAdobe) {
      return (
        <Skeleton repeat={1}>
          <Table.Row>
            <Skeleton repeat={10}>
              <Table.Cell>
                <Skeleton.Text />
              </Table.Cell>
            </Skeleton>
          </Table.Row>
        </Skeleton>
      );
    }
  }, [adobeGroups, isLoadingAdobe]);

  return (
    <div className={styles.main}>
      <div>
        <SearchBarFilter
          title={NameElement}
          rawSubscriptionData={externalSubscriptions}
          setFilteredData={setFilteredData}
          filteredData={sortedData}
          content={HeaderContent}
        />
        <Table noBorder>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('skuName')}>
                Subscription
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('vendor.name')}>
                Vendor
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('agreementType')}>
                Agreement
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('availableUnits')}>
                Available units
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('groupId')}>
                Azure AD Group
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('termDuration')}>
                Term
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('renewalDate')}>
                Renewal date
              </Table.HeaderCell>
              <Table.HeaderCell>Reminder</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell> {/* Empty header cell as required */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <ShowExternalSubscriptions
              rawSubscriptionData={externalSubscriptions}
              renderGroupName={renderGroupName}
              isloading={isLoadingExternalSubscriptions}
              error={error}
              filteredData={sortedData}
              setSelectedSubscription={setSelectedSubscription}
              setShowConnectedManageDrawer={setShowConnectedManageDrawer}
            />
            {showAdobe}
          </Table.Body>
        </Table>
      </div>
      <Drawer
        className={styles.drawer}
        header="Add document subscription"
        isOpen={externalVendorDrawer}
        onRequestClose={() => setExternalVendorDrawer(false)}
        overlay
      >
        <ExternalSubscriptionForm
          externalSubscription={externalSubscription}
          setExternalVendorDrawer={setExternalVendorDrawer}
          tenantId={assuredTenantId}
        />
      </Drawer>
      <Drawer
        isOpen={showConnectedManageDrawer}
        onRequestClose={() => setShowConnectedManageDrawer(false)}
        overlay
        header={'Manage subscription'}
        className={styles.drawer}
      >
        <ManageSubsDrawer
          selectedSubscription={selectedSubscription}
          tenantId={assuredTenantId}
          groups={groups}
          setShowManageDrawer={setShowConnectedManageDrawer}
          usage={'documented'}
          handleMSMutate={handleMSMutate}
          MSProps={MSProps}
        />
      </Drawer>
    </div>
  );
});

Documented.displayName = 'Documented';

export default Documented;
