import { FC } from 'react';
import { Control, Controller, SetFieldValue } from 'react-hook-form';
import { Badge } from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import styles from './DateSelect.module.css';
import useDateSelect from './useDateSelect';
import { OrderAvailability } from 'models/OrderAvailability';
import formatDate from 'helpers/formatDate/FormatDate';

export interface CleansedData {
  date: any;
  amount: number;
  license: any;
}

interface DateSelectProps {
  control: Control<any>;
  setValue: SetFieldValue<any>;
  cleansedData: CleansedData[];
  availability: OrderAvailability | null;
}

const DateSelect: FC<DateSelectProps> = ({ cleansedData, control, setValue, availability }) => {
  const { options, selected, setSelected, formatOptionLabel } = useDateSelect({ cleansedData, setValue });

  return (
    <>
      <Controller
        name="date"
        control={control}
        render={({ field: { value } }) => (
          <Select
            label="Choose end date"
            placeholder={formatDate(selected)}
            value={value}
            onChange={(item) => setSelected(item!.value)}
            options={options}
            formatOptionLabel={formatOptionLabel}
            className={styles.basicSelect}
            isDisabled={!availability}
          />
        )}
      />
      <p className="bf-medium" style={{ margin: 0, padding: 0 }}>
        Other subscriptions on the same date:
      </p>
      <div className={styles.badgeWrapper}>
        {cleansedData
          .filter((data) => data.date === selected)
          .flatMap((data) => data.license)
          .slice(0, 10)
          .map((item, index) => (
            <Badge key={index} pill className={styles.badges}>
              {item.label + ' ' + '(' + item.amount + ') '}
            </Badge>
          ))}
        {cleansedData.filter((data) => data.date === selected).flatMap((data) => data.license).length > 10 && (
          <Badge pill className={styles.badges}>
            + {cleansedData.filter((data) => data.date === selected).flatMap((data) => data.license).length - 10} more
          </Badge>
        )}
      </div>
    </>
  );
};

export default DateSelect;
