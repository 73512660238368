import { Message, Modal, Badge, Icon, Button } from '@intility/bifrost-react';
import styles from './SubscriptionStatus.module.css';

const SubscriptionStatus = ({
  status,
  subscription,
  selectedSubscriptionStatus,
  SubscriptionModal,
  setSubScriptionModal,
  setSelectedSubscriptionStatus,
  handleStatusChange,
  handleCancel,
  isLoading,
  loading,
}) => {
  return (
    <div className={styles.SubscriptionStatusContainer}>
      {status === 'Active' && <Message state="success">State: Active</Message>}
      {status === 'Suspended' && <Message state="warning">State: Suspended</Message>}
      {status === 'Deleted' && <Message state="alert">State: Deleted</Message>}

      <Modal onRequestClose={() => setSubScriptionModal(false)} header="Subscription status" isOpen={SubscriptionModal}>
        <div>
          <p className="bf-medium">{subscription?.name}</p>

          {selectedSubscriptionStatus === 'Deleted' ? (
            <>
              <span className="bf-strong">
                Are you sure you want to permanently delete <Badge state="alert">{subscription?.name}</Badge> ?
              </span>
              <p className="bf-strong">This action is irreversible</p>
            </>
          ) : (
            <span className="bf-strong">
              Change status from{' '}
              {status === 'Deleted' ? (
                <Badge state="alert">{status}</Badge>
              ) : status === 'Suspended' ? (
                <Badge state="warning">{status}</Badge>
              ) : (
                <Badge state="success">{status}</Badge>
              )}
              to{' '}
              {selectedSubscriptionStatus === 'Deleted' ? (
                <Badge state="alert">{selectedSubscriptionStatus}</Badge>
              ) : selectedSubscriptionStatus === 'Suspended' ? (
                <Badge state="warning">{selectedSubscriptionStatus}</Badge>
              ) : (
                <Badge state="success">{selectedSubscriptionStatus}</Badge>
              )}
              ?
            </span>
          )}
        </div>

        {isLoading || loading ? (
          <div className={styles.spinner}>
            <Icon.Spinner size={20} />
            <p>Setting state: {selectedSubscriptionStatus}</p>
          </div>
        ) : (
          <div className={styles.buttonGroup}>
            {selectedSubscriptionStatus === 'Active' && (
              <Button
                state="default"
                variant="filled"
                disabled={isLoading || loading}
                onClick={() => handleStatusChange(selectedSubscriptionStatus)}
              >
                Activate
              </Button>
            )}
            {selectedSubscriptionStatus === 'Suspended' && (
              <Button
                state="alert"
                variant="outline"
                disabled={isLoading || loading}
                onClick={() => handleStatusChange(selectedSubscriptionStatus)}
              >
                Suspend
              </Button>
            )}
            {selectedSubscriptionStatus === 'Deleted' && (
              <Button
                state="alert"
                variant="filled"
                disabled={isLoading || loading}
                onClick={() => handleStatusChange(selectedSubscriptionStatus)}
              >
                Delete
              </Button>
            )}
            {isLoading || loading ? null : (
              <Button
                disabled={isLoading || loading}
                onClick={() => {
                  setSubScriptionModal(false);
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        )}
      </Modal>

      {status === 'Active' && (
        <div>
          <Button
            state="alert"
            variant="outline"
            title="Suspend connection"
            small
            onClick={() => {
              setSubScriptionModal(true);
              setSelectedSubscriptionStatus('Suspended');
            }}
          >
            Suspend
          </Button>{' '}
          <Button
            state="alert"
            variant="filled"
            title="Delete connection"
            small
            onClick={() => {
              setSubScriptionModal(true);
              setSelectedSubscriptionStatus('Deleted');
            }}
          >
            Delete
          </Button>
        </div>
      )}
      {status === 'Suspended' && (
        <div>
          <Button
            state="default"
            variant="outline"
            title="Activate connection"
            small
            onClick={() => {
              setSubScriptionModal(true);
              setSelectedSubscriptionStatus('Active');
            }}
          >
            Activate
          </Button>{' '}
          <Button
            state="alert"
            variant="filled"
            title="Delete connection"
            small
            onClick={() => {
              setSubScriptionModal(true);
              setSelectedSubscriptionStatus('Deleted');
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionStatus;
