import { Checkbox, Input } from '@intility/bifrost-react';
import styles from './ReminderSettings.module.css'; // Adjust the path according to your project structure

const ReminderSettings = ({
  reminderCheckBox,
  setReminderCheckBox,
  reminderQuantity,
  setReminderQuantity,
  setReminderChanged,
  isLoading,
  loading,
}) => {
  return (
    <>
      <p className="bf-medium bf-strong">Reminder for licence quantity reaching the limit</p>
      <Checkbox
        label={'Set reminder'}
        align="right"
        type="switch"
        checked={reminderCheckBox}
        className={styles.reminderSwitch}
        onChange={() => {
          setReminderCheckBox((prevState) => !prevState);
          setReminderChanged(true);
        }}
        disabled={isLoading || loading}
      />
      <Input
        label={'Limit at which to start sending reminder'}
        placeholder="-Licenses"
        type="number"
        value={reminderQuantity}
        onChange={(e) => {
          setReminderQuantity(Number(e.target.value));
          setReminderChanged(true);
        }}
        disabled={isLoading || loading || !reminderCheckBox}
      />
    </>
  );
};

export default ReminderSettings;
