import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { AlsoConnection } from 'models/AlsoMarketPlace';

export const fetchAlsoConnectionID = async (tenantId: string) => {
  try {
    const response = await authorizedFetch<AlsoConnection | null>(
      `${BaseAPIEndpoint}Also/connectedcustomer/${tenantId}`,
      {
        method: 'GET',
      },
    );
    if (!response.data) {
      throw new Error('Error fetching products');
    }
    return response;
  } catch (error) {
    console.log('Error fetching products', error);
    throw error;
  }
};
