import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

export const deleteHandledAlsoSubscription = async (tenantId, handledID) => {
  try {
    const response = await authorizedFetch(`${BaseAPIEndpoint}Also/handledSubscription/${handledID}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.data) {
      throw new Error('Error setting subscription as unhandled');
    }
    return response;
  } catch (error) {
    console.log('Error setting subscription as unhandled', error);
    throw error;
  }
};
