import { FC, useCallback, useEffect, useState } from 'react';
import styles from './OrderSubscriptionForm.module.css';
import OrderSubmitPageOne from './components/OrderSubmitPageOne';
import OrderSubmitPageTwo from './components/OrderSubmitPageTwo';
import OrderSubmitPageSummary from './components/OrderSubmitPageSummary';
import { orderNewSubscription } from 'api/fetchers/orderNewSubscription';
import { countryCodeOptions } from './utils/CountryCode';
import { fetchSubscriptionOrderAvailability } from 'api/fetchers/fetchSubscriptionOrderAvailability';
import { fetchSubscriptionOrderProducts } from 'api/fetchers/fetchSubscriptionOrderProducts';
import { fetchSubscriptionOrderSkus } from 'api/fetchers/fetchSubscriptionOrderSkus';
import { OptionType } from 'models';
import { OrderAvailability } from 'models/OrderAvailability';
import { OrderSku, SkuSelectOption } from 'models/OrderSkus';
import { useForm } from 'react-hook-form';
import { CountryCode } from 'models/OrderCountryCode';
import { SegmentOption } from 'models/OrderSegment';
import { Button, Icon, useFloatingMessage } from '@intility/bifrost-react';
import SpinnerButton from 'components/common/spinnerButton/SpinnerButton';
import ProgressBar from './components/ProgressBar';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface OrderSubscriptionProps {
  tenantId: string;
  setOrderSubDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  orderStateCancel: boolean;
  setOrderStateCancel: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface OrderSubscriptionFormProps {
  segment: SegmentOption;
  license: OptionType | null;
  productSku: OrderSku | null;
  countryCode: CountryCode;
  availability: OrderAvailability | null;
  quantity: number | null;
  date: string | null;
}

interface OnSubmitProps {
  segment: SegmentOption;
  license: OptionType | null;
  productSku: OrderSku | null;
  countryCode: CountryCode;
  availability: OrderAvailability | null;
  quantity: number | null;
  date: string | null;
}

const segmentOptions: SegmentOption[] = [
  { value: 'Commercial', label: 'Commercial' },
  { value: 'Government', label: 'Government' },
  { value: 'Education', label: 'Education' },
  { value: 'NonProfit', label: 'NonProfit' },
];

const OrderSubscriptionForm: FC<OrderSubscriptionProps> = ({
  tenantId,
  setOrderSubDrawer,
  orderStateCancel,
  setOrderStateCancel,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAvailabilityLoading, setIsAvailabilityLoading] = useState<boolean>(false);
  const [isLicenseLoading, setIsLicenseLoading] = useState<boolean>(false);
  const [isSkuLoading, setIsSkuLoading] = useState<boolean>(false);
  const [availableLicenses, setAvailableLicenses] = useState<OptionType[] | undefined>(undefined);
  const [availableSkus, setAvailableSkus] = useState<OrderSku[] | undefined>(undefined);
  const [isAvailability, setIsAvailability] = useState<OrderAvailability[] | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState(1);
  const [isBillingButtonDisabled, setIsBillingButtonDisabled] = useState<boolean>(true);
  const [isSummaryButtonDisabled, setIsSummaryButtonDisabled] = useState<boolean>(true);
  const totalSteps = 3;
  const { showFloatingMessage } = useFloatingMessage();
  const TIMEOUT_DURATION = 2000;

  const { control, setValue, watch, handleSubmit } = useForm<OrderSubscriptionFormProps>({
    defaultValues: {
      segment: segmentOptions[0],
      license: null,
      productSku: null,
      countryCode: countryCodeOptions[164],
      availability: null,
      quantity: 1,
      date: null,
    },
  });

  const segment = watch('segment');
  const license = watch('license');
  const productSkuId = watch('productSku');
  const countryCode = watch('countryCode');
  const quantity = watch('quantity');
  const availability = watch('availability');
  const date = watch('date');

  //Setting form progression locked until all fields are filled
  useEffect(() => {
    if (segment && license && productSkuId && quantity) {
      setIsBillingButtonDisabled(false);
    } else {
      setIsBillingButtonDisabled(true);
    }
  }, [segment, license, productSkuId, quantity]);

  //Setting form progression locked until all fields are filled
  useEffect(() => {
    if (countryCode && availability) {
      setIsSummaryButtonDisabled(false);
    } else {
      setIsSummaryButtonDisabled(true);
    }
  }, [countryCode, availability]);

  const fetchProducts = useCallback(async () => {
    try {
      setErrorMessage(null);
      setAvailableLicenses(undefined);
      setValue('license', null);
      setIsLicenseLoading(true);
      const data = await fetchSubscriptionOrderProducts(tenantId, segment.value);

      const productOptions: OptionType[] = data.map((product) => ({
        value: product.id,
        label: product.title,
      }));

      setAvailableLicenses(productOptions);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setErrorMessage('Could not fetch products, please try again or refresh the page');
    } finally {
      setIsLicenseLoading(false);
    }
  }, [setValue, tenantId, segment.value]);

  const fetchSkus = useCallback(async () => {
    if (!license) {
      setAvailableSkus(undefined);
      return;
    }

    try {
      setErrorMessage(null);
      setAvailableSkus(undefined);
      setValue('productSku', null);
      setIsSkuLoading(true);
      const data = await fetchSubscriptionOrderSkus(tenantId, license.value);

      const skuOptions: SkuSelectOption[] = data.map((sku) => ({
        id: sku.id,
        productId: sku.productId,
        title: sku.title,
        description: sku.description,
        value: sku.id,
        label: sku.title,
      }));

      setAvailableSkus(skuOptions);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setErrorMessage('Could not fetch skus, please try again or refresh the page');
    } finally {
      setIsSkuLoading(false);
    }
  }, [license, setValue, tenantId]);

  useEffect(() => {
    if (license) {
      fetchSkus();
    }
  }, [fetchSkus, license]);

  const fetchAvailability = useCallback(async () => {
    if (!productSkuId || !countryCode || !license) {
      setIsAvailability(undefined);
      return;
    }

    try {
      setErrorMessage(null);
      setIsAvailability(undefined);
      setValue('availability', null);
      setIsAvailabilityLoading(true);
      const data = await fetchSubscriptionOrderAvailability(license.value, productSkuId.id, countryCode.value);

      const availabilityOptions: OrderAvailability[] = data.flatMap((avail) =>
        avail.terms.map((term) => ({
          catalogItemId: avail.catalogItemId,
          terms: avail.terms,
          value: term.id,
          label: `${term.duration} - ${term.billingCycle}`,
          billingCycle: term.billingCycle,
          termDuration: term.duration,
        })),
      );
      setIsAvailability(availabilityOptions);

      if (availabilityOptions.length > 0) {
        const matchingOption = availabilityOptions.find((option) => option.label === 'P1Y - Monthly');
        if (matchingOption) {
          setValue('availability', matchingOption);
        } else {
          setValue('availability', null);
        }
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      setErrorMessage('Could not fetch availability, please try again or refresh the page');
    } finally {
      setIsAvailabilityLoading(false);
    }
  }, [countryCode, license, productSkuId, setValue]);

  useEffect(() => {
    if (productSkuId && countryCode && license) {
      fetchAvailability();
    }
  }, [productSkuId, countryCode, license, fetchAvailability]);

  const handleResetForm = useCallback(() => {
    setErrorMessage(null);
    setIsSuccess(false);
    fetchProducts();
    setAvailableSkus(undefined);
    setIsAvailability(undefined);
    setValue('productSku', null);
    setValue('countryCode', countryCodeOptions[164]);
    setValue('availability', null);
    setValue('quantity', 1);
    setValue('date', null);
    setCurrentStep(1);
  }, [fetchProducts, setValue]);

  //Reset form when segment changes
  useEffect(() => {
    handleResetForm();
  }, [handleResetForm, segment]);

  const handleCancel = useCallback(() => {
    setOrderSubDrawer(false);
    handleResetForm();
  }, [handleResetForm, setOrderSubDrawer]);

  useEffect(() => {
    if (orderStateCancel) {
      handleCancel();
      setOrderStateCancel(false);
    }
  }, [orderStateCancel, handleCancel, setOrderStateCancel]);

  const onSubmit = async (data: OnSubmitProps) => {
    setIsSubmitting(true);

    try {
      if (!data.availability || !data.quantity || !data.date) {
        return;
      }
      const response = await orderNewSubscription(tenantId, data.availability, data.quantity, data.date);
      if (response.code) {
        setIsSubmitting(false);
        if (response.code === 201 || response.code === 200) {
          setIsSuccess(true);
          setTimeout(() => {
            handleCancel();
          }, TIMEOUT_DURATION);
        }
      }
    } catch (error) {
      console.error('Error submitting order: ', error);
      setErrorMessage('Could not submit order, please try again');
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const goToNextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <OrderSubmitPageOne
            control={control}
            segmentOptions={segmentOptions}
            availableLicenses={availableLicenses}
            availableSkus={availableSkus}
            isLicenseLoading={isLicenseLoading}
            isSkuLoading={isSkuLoading}
            setValue={setValue}
            watch={watch}
          />
        );
      case 2:
        return (
          <OrderSubmitPageTwo
            control={control}
            isAvailabilityLoading={isAvailabilityLoading}
            isAvailability={isAvailability}
            tenantId={tenantId}
            setValue={setValue}
            watch={watch}
            availability={availability}
          />
        );
      case 3:
        return (
          <OrderSubmitPageSummary
            segment={segment}
            license={license}
            productSkuId={productSkuId}
            countryCode={countryCode}
            quantity={quantity}
            availability={availability}
            date={date}
          />
        );
      default:
        return <></>;
    }
  };
  useEffect(() => {
    if (isSuccess || errorMessage || isSubmitting) {
      const scrollTriggerElement = document.getElementById('scroll-trigger');
      if (scrollTriggerElement) {
        scrollTriggerElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
    if (errorMessage) {
      showFloatingMessage(errorMessage, { state: 'alert' });
    } else if (isSuccess) {
      showFloatingMessage('Order submitted', { state: 'success' });
    } else if (isSubmitting) {
      showFloatingMessage('Submitting order...', { state: 'default' });
    }
  }, [isSuccess, errorMessage, isSubmitting, showFloatingMessage]);

  return (
    <div className={styles.mainFormContainer}>
      <ProgressBar currentStep={currentStep} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderFormStep()}
        <div className={styles.drawerButtons}>
          {currentStep > 1 && (
            <Button variant="outline" type="button" onClick={goToPreviousStep}>
              <Icon icon={faArrowLeft}></Icon> Previous step
            </Button>
          )}

          {currentStep === 1 && (
            <>
              <div></div>
              <Button
                className={isBillingButtonDisabled ? styles.btnDisabled : styles.btnEnabled}
                variant="outline"
                type="button"
                onClick={goToNextStep}
              >
                Go to billing details <Icon icon={faArrowRight}></Icon>
              </Button>
            </>
          )}
          {currentStep === 2 && (
            <Button
              className={isSummaryButtonDisabled ? styles.btnDisabled : styles.btnEnabled}
              variant="outline"
              type="button"
              onClick={goToNextStep}
            >
              Go to order summary <Icon icon={faArrowRight}></Icon>
            </Button>
          )}
          {currentStep === 3 && <SpinnerButton isSubmitting={isSubmitting} buttonText={'Submit order'}></SpinnerButton>}
        </div>
      </form>
    </div>
  );
};
export default OrderSubscriptionForm;
