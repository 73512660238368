import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { Directory } from 'models';
import { useEffect, useState } from 'react';

/**
 * Fetch all directories from a customer.
 * @param tenantId
 */

export const useGetDirectories = (tenantId: string | undefined) => {
  const [directories, setDirectories] = useState<Directory[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      try {
        const response = await authorizedFetch<Directory[]>(`${BaseAPIEndpoint}tenants/${tenantId}/directories`, {
          method: 'GET',
        });
        if (response.data) {
          setDirectories(response.data);
          return;
        }
        return [];
      } catch (error) {
        setError('Error fetching directories object');
        console.log('Error fetching directories object', error);
        throw new Error('Failed to fetch directories');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [tenantId]);

  return { directories, isLoading, error };
};
