import { FC, useMemo } from 'react';
import { Badge, Button, Icon, Skeleton, Table } from '@intility/bifrost-react';
import { CopyIconButton } from 'components';
import TableFeedback from 'components/common/tableFeedback/TableFeedback';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { AlsoSubscription } from 'models/AlsoMarketPlace';

interface UnconnectedSubscriptionsProps {
  rawSubscriptionData?: AlsoSubscription[];
  isLoading?: boolean;
  setShowUnconnectedManageDrawer: any;
  setSelectedMarketSubscription: any;
  filteredUnhandledData?: any[];
}

export const ShowUnconnectedSubscriptions: FC<UnconnectedSubscriptionsProps> = ({
  rawSubscriptionData,
  isLoading,
  setShowUnconnectedManageDrawer,
  setSelectedMarketSubscription,
  filteredUnhandledData,
}) => {
  const unconnectedSubscriptions = useMemo(() => {
    const handleManageClick = (config: AlsoSubscription) => {
      setShowUnconnectedManageDrawer(true);
      if (config) {
        setSelectedMarketSubscription(config);
      }
    };
    if (filteredUnhandledData && filteredUnhandledData.length > 0 && !isLoading) {
      return filteredUnhandledData.map((config) => (
        <Table.Row key={config.accountId}>
          <Table.Cell>
            <CopyIconButton copyString={config.accountId} />
            {config.serviceDisplayName}
          </Table.Cell>
          <Table.Cell>{config.language}</Table.Cell>
          <Table.Cell>{config.accountId}</Table.Cell>
          <Table.Cell>{config.quantity}</Table.Cell>
          <Table.Cell>
            {config.advancePeriodEndAction === 'Renew' ? (
              <Badge pill>Yes</Badge>
            ) : config.advancePeriodEndAction === null ? (
              <Badge state="neutral" pill>
                N/A
              </Badge>
            ) : (
              <Badge state="alert" pill>
                Off
              </Badge>
            )}
          </Table.Cell>
          <Table.Cell align="right">
            <Button small variant="outline" onClick={() => handleManageClick(config)}>
              <Icon icon={faCog}></Icon> Manage
            </Button>
          </Table.Cell>
        </Table.Row>
      ));
    }
    if (isLoading) {
      return (
        <Skeleton repeat={2}>
          <Table.Row>
            <Skeleton repeat={5}>
              <Table.Cell>
                <Skeleton.Text />
              </Table.Cell>
            </Skeleton>
          </Table.Row>
        </Skeleton>
      );
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={9}>
            <TableFeedback
              unfilteredData={rawSubscriptionData}
              filteredData={filteredUnhandledData}
              emptyMessage={'No unconnected subscriptions present'}
            />
          </Table.Cell>
        </Table.Row>
      );
    }
  }, [
    filteredUnhandledData,
    isLoading,
    rawSubscriptionData,
    setSelectedMarketSubscription,
    setShowUnconnectedManageDrawer,
  ]);

  return <>{unconnectedSubscriptions}</>;
};

export default ShowUnconnectedSubscriptions;
