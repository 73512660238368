import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

export const removeAlsoConnection = async (alsoCompanyAccountId, msTenantID) => {
  try {
    const response = await authorizedFetch(
      `${BaseAPIEndpoint}Also/removeConnection/${msTenantID}/${alsoCompanyAccountId}`,
      {
        method: 'DELETE',
        body: JSON.stringify({
          alsoCompanyAccountId: { alsoCompanyAccountId },
          microsoftTenantId: { msTenantID },
        }),
      },
    );
    if (!response.data) {
      throw new Error('Error deleting connection');
    }
    return response;
  } catch (error) {
    console.log('Error deleting connection', error);
    throw error;
  }
};
