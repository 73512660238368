import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
export const postNewEntraGroup = async (
  tenantId: string,
  subscriptionId: string,
  selectedMethod: string,
  syncGroup: boolean,
  inputValue: string,
) => {
  const object = {
    tenantId,
    subscriptionId,
    orderMethod: selectedMethod,
    addToSync: syncGroup,
    name: inputValue,
  };

  try {
    console.log('Entra Object to be posted:  ' + JSON.stringify(object, null, 2));
    const response = await authorizedFetch(`${BaseAPIEndpoint}Groups/entra`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    });
    if (!response.data) {
      throw new Error('Error posting Entra group config');
    }
    return response;
  } catch (error) {
    console.log('Error posting Entra group config', error);
    throw error;
  }
};
