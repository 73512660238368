import useSWR from 'swr';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { AlsoGroupConnection } from 'models/alsoConnectedSubs';

/**
 * Fetch connected Also Subscriptions.
 * @param tenantId
 * @param fetchAlsoDetails
 */

export const useAlsoConnectedSubscriptions = (tenantId: string | undefined, fetchAlsoDetails: boolean) => {
  const fetchAlsoConnectedSubscriptions = async () => {
    if (!fetchAlsoDetails) {
      return null;
    }
    try {
      const response = await authorizedFetch<AlsoGroupConnection[] | null>(
        `${BaseAPIEndpoint}Also/tenant/${tenantId}/connectedAlsoSubscriptions`,
        {
          method: 'GET',
        },
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching SubscriptionConnection object', error);
      throw error;
    }
  };

  return useSWR('alsoConnectedSubscriptions', fetchAlsoConnectedSubscriptions);
};
