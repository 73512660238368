import useSWR from 'swr';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { HandledConnectedSubscription } from 'models/SubscriptionConnection';

/**
 * Fetch connected Also Subscriptions.
 * @param tenantId
 */

export const useAlsoHandledUnconnectedSubscriptions = (tenantId: string | undefined) => {
  const getAlsoHandledUncSubs = async () => {
    try {
      const response = await authorizedFetch<HandledConnectedSubscription[] | null>(
        `${BaseAPIEndpoint}Also/tenant/${tenantId}/handledSubscriptions`,
        {
          method: 'GET',
        },
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching handled Subscriptions object', error);
      throw error;
    }
  };

  return useSWR('alsoHandledUncSubs', getAlsoHandledUncSubs);
};
