import React, { FC, useEffect, useState } from 'react';
import styles from './UnConnected.module.css';
import { Drawer, Table } from '@intility/bifrost-react';
import ShowUnconnectedSubscriptions from './ShowUnconnectedSubscriptions';
import SearchBarFilter from '../../../components/SearchBarFilter';
import { AlsoSubscription } from 'models/AlsoMarketPlace';
import { useSorting } from 'helpers/hooks';
import ManageMarketDrawer from '../manageSubsForm/ManageMarketDrawer';
import { MarketProps } from 'models/TenantConfigProps';

interface UnConnectedProps {
  MarketProps: MarketProps;
}

const UnConnected: FC<UnConnectedProps> = React.memo(({ MarketProps }) => {
  const {
    groups,
    assuredTenantId,
    isLoadingAlsoUnconnectedSubscription,
    handleMarketMutate,
    setAmountOfHandledUnconnectedALSO,
    setAmountOfUnhandledUnconnectedALSO,
    HandledAlsoSubs,
    UnhandledAlsoSubs,
    isLoadingAlsoHandledUnconnectedSubscriptions,
  } = MarketProps;

  //Passing jsx as a variable to the searchbarfilter component
  const UnhandledNameElement = (
    <>
      <span className={styles.grayName}>Unconnected: </span>
      <span className={styles.defaultName}>Unhandled subscriptions</span>
    </>
  );
  const HandledNameElement = (
    <>
      <span className={styles.grayName}>Unconnected: </span>
      <span className={styles.defaultName}>Handled subscriptions</span>
    </>
  );
  const [showUnconnectedManageDrawer, setShowUnconnectedManageDrawer] = useState(false);
  const [selectedMarketSubscription, setSelectedMarketSubscription] = useState<any>({});
  const [handledUnconnected, setHandledUnconnected] = useState<any[]>([]);
  const [unhandledUnconnected, setUnhandledUnconnected] = useState<any[]>([]);

  useEffect(() => {
    if (HandledAlsoSubs) {
      setHandledUnconnected(HandledAlsoSubs);
      setAmountOfHandledUnconnectedALSO(HandledAlsoSubs.length);
    }

    if (UnhandledAlsoSubs) {
      setUnhandledUnconnected(UnhandledAlsoSubs);
      setAmountOfUnhandledUnconnectedALSO(UnhandledAlsoSubs.length);
    }
  }, [HandledAlsoSubs, UnhandledAlsoSubs, setAmountOfHandledUnconnectedALSO, setAmountOfUnhandledUnconnectedALSO]);

  const [filteredUnhandledData, setFilteredUnhandledData] = useState<any[]>([]);
  const [filteredHandledData, setFilteredHandledData] = useState<any[]>([]);

  const { getHeaderCellProps, sortedData: sortedDataUnhandled } = useSorting<AlsoSubscription>(
    filteredUnhandledData || [],
  );
  const { sortedData: sortedDataHandled } = useSorting<AlsoSubscription>(filteredHandledData || []);

  return (
    <div className={styles.main}>
      <div className={styles.tableMains}>
        {/* UNHANDLED */}
        <SearchBarFilter
          title={UnhandledNameElement}
          rawSubscriptionData={unhandledUnconnected}
          setFilteredData={setFilteredUnhandledData}
          filteredData={sortedDataUnhandled}
        />
        <Table noBorder>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ color: 'var(--bfc-base-c-1)', width: '40%' }}
                {...getHeaderCellProps('serviceDisplayName')}
              >
                Product
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('language')}>
                Language
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('accountId')}>
                Subscription Id
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('quantity')}>
                Licenses
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: 'var(--bfc-base-c-1)' }}
                {...getHeaderCellProps('advancePeriodEndAction')}
              >
                Auto renewal
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <ShowUnconnectedSubscriptions
              rawSubscriptionData={unhandledUnconnected}
              isLoading={isLoadingAlsoUnconnectedSubscription}
              setShowUnconnectedManageDrawer={setShowUnconnectedManageDrawer}
              setSelectedMarketSubscription={setSelectedMarketSubscription}
              filteredUnhandledData={sortedDataUnhandled}
            />
          </Table.Body>
        </Table>
      </div>
      {/* HANDLED */}
      <div>
        <SearchBarFilter
          title={HandledNameElement}
          filteredData={sortedDataHandled}
          setFilteredData={setFilteredHandledData}
          rawSubscriptionData={handledUnconnected}
        />
        <Table noBorder>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ color: 'var(--bfc-base-c-1)', width: '40%' }}
                {...getHeaderCellProps('serviceDisplayName')}
              >
                Product
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('language')}>
                Language
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('accountId')}>
                Subscription Id
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('quantity')}>
                Licenses
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: 'var(--bfc-base-c-1)' }}
                {...getHeaderCellProps('advancePeriodEndAction')}
              >
                Auto renewal
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <ShowUnconnectedSubscriptions
              rawSubscriptionData={handledUnconnected}
              isLoading={isLoadingAlsoHandledUnconnectedSubscriptions}
              setShowUnconnectedManageDrawer={setShowUnconnectedManageDrawer}
              setSelectedMarketSubscription={setSelectedMarketSubscription}
              filteredUnhandledData={sortedDataHandled}
            />
          </Table.Body>
        </Table>
      </div>
      {/* COMMON */}
      <Drawer
        isOpen={showUnconnectedManageDrawer}
        onRequestClose={() => setShowUnconnectedManageDrawer(false)}
        overlay
        header={'Manage subscription'}
        className={styles.drawer}
      >
        <ManageMarketDrawer
          selectedMarketSubscription={selectedMarketSubscription}
          tenantId={assuredTenantId}
          groups={groups}
          setShowManageDrawer={setShowUnconnectedManageDrawer}
          usage={'unconnected'}
          handleMarketMutate={handleMarketMutate}
        />
      </Drawer>
    </div>
  );
});

UnConnected.displayName = 'UnConnected';

export default UnConnected;
