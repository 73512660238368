import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { ExternalSubscription } from '../../models';
import { authorizedFetch } from 'auth/authorizedFetch';

async function sendExternalSubscriptions(extSub: ExternalSubscription): Promise<any> {
  return authorizedFetch(`${BaseAPIEndpoint}externalSubscriptions/subscription`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(extSub),
  });
}

export const usePostExternalSubscription = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const TIMEOUT_DURATION = 2000;

  const sendExternalSubscription = useCallback(async (extSub: ExternalSubscription): Promise<any> => {
    try {
      setLoading(true);
      setError(false);
      const response = await sendExternalSubscriptions(extSub);
      if (response.code === 201) {
        setTimeout(() => setLoading(false), TIMEOUT_DURATION);
        setError(false);
        return true;
      } else {
        console.log('Error sending external subscription', response.message);
        setLoading(false);
        setError(true);
      }
    } catch (errorMessage) {
      console.log('Error sending external subscription', errorMessage);
      setLoading(false);
      setError(true);
    }
  }, []);

  return {
    error,
    loading,
    sendExternalSubscription,
  };
};
