import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

async function createGroupConfigCall(
  tenantId: string,
  subscriptionId: string,
  groupId: string,
  orderMethod: string,
  groupBasedLicensing: boolean,
): Promise<any> {
  return authorizedFetch(`${BaseAPIEndpoint}tenants/${tenantId}/SubscriptionConnections`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      subscriptionId: subscriptionId,
      groupId: groupId,
      orderMethod: orderMethod,
      groupBasedLicensing: groupBasedLicensing,
    }),
  });
}

export const usePostGroupConfigurations = () => {
  const [loading, setLoading] = useState(false);
  const [configError, setConfigError] = useState(false);

  const createGroupConfig = useCallback(
    async (
      tenantId: string,
      subscriptionId: string,
      groupId: string,
      orderMethod: string,
      groupBasedLicensing: boolean,
    ) => {
      setLoading(true);
      try {
        const response = await createGroupConfigCall(
          tenantId,
          subscriptionId,
          groupId,
          orderMethod,
          groupBasedLicensing,
        );
        if (response.code === 200) {
          return true;
        } else {
          setConfigError(true);
        }
      } catch (errorMessage) {
        console.log('Error creating group configuration', errorMessage);
        setConfigError(true);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    createGroupConfig,
    loading,
    setConfigError,
    configError,
  };
};
