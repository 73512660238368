import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Input, Message, Icon } from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import { useTenants } from '../../../../helpers/hooks';
import { OptionType } from '../../../../models';
import styles from '../../UserInfo.module.css';

interface UserFormProps {
  error: boolean;
  setUserDetails: React.Dispatch<React.SetStateAction<undefined>>;
  getUserLicenseDetails: (tenantId: string, userId: string) => Promise<any>;
}

const UserForm = ({ error, setUserDetails, getUserLicenseDetails }: UserFormProps) => {
  const location = useLocation();
  const { data, isValidating } = useTenants();

  const [formError, setFormError] = useState<boolean>(false);
  const [upnError, setUpnError] = useState<boolean>(false);
  const [user, setUser] = useState<string>('');
  const [tenant, setTenant] = useState<OptionType>({ value: '', label: '' });

  const tenantList = useMemo(() => {
    return data?.map((_tenant) => {
      return {
        value: _tenant.id,
        label: `${_tenant.name}`,
        domain: _tenant.domain,
      };
    });
  }, [data]);

  const handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!user) {
      setUpnError(true);
    }
    if (!tenant.value) {
      setFormError(true);
    }
    if (user && tenant.value) {
      setUserDetails(undefined);
      getUserLicenseDetails(tenant.value, user).then((response) => {
        setUserDetails(response);
      });
    }
  };

  useEffect(() => {
    const searchParams: any = location.state;
    let selectedTenant;
    if (location.state) {
      setUser(searchParams.user);
      if (tenantList) {
        selectedTenant = tenantList.find((_tenant) => _tenant.value === searchParams.tenant.tenantId);
        setTenant({ value: selectedTenant.value, label: selectedTenant.label });
      }
      setUserDetails(undefined);
      getUserLicenseDetails(searchParams.tenant.tenantId, searchParams.user).then((response) =>
        setUserDetails(response),
      );
    }
  }, [getUserLicenseDetails, location.state, setUserDetails, tenantList]);

  return (
    <div>
      <form onSubmit={handleOnSubmit} className={styles.form}>
        <div className={styles.select}>
          <Select
            onBlur={() => setFormError(false)}
            state={formError ? 'alert' : 'default'}
            feedback={formError ? 'Please select a tenant' : null}
            isClearable
            value={tenant}
            placeholder={
              isValidating ? (
                <div className={styles.loadingInput}>
                  {' '}
                  <Icon.Spinner size={16} /> Getting data
                </div>
              ) : (
                '- Select tenant -'
              )
            }
            label="Select tenant"
            options={tenantList}
            formatOptionLabel={(option) => (
              <div>
                <span>{option.label}</span>
                <div style={{ fontSize: '0.8em', color: '#888' }}>{option.domain}</div>
              </div>
            )}
            onChange={(selectedOption) => {
              if (selectedOption === null) {
                setTenant({ value: '', label: '' });
              } else {
                setTenant(selectedOption);
              }
            }}
          />
        </div>
        <div className={styles.input}>
          <Input
            onBlur={() => setUpnError(false)}
            state={upnError ? 'alert' : 'default'}
            feedback={upnError ? 'Please provide us with a users UPN' : null}
            label="Find a user"
            value={user}
            id="user"
            onChange={(input) => setUser(input.target.value)}
            placeholder="- UPN or ID -"
          />
        </div>
        <Button variant="filled" className={styles.submit} state="default" type="submit">
          Find user
        </Button>
      </form>
      {error && (
        <Message style={{ width: 950 }} state="warning">
          Sorry the user couldn&apos;t be found
        </Message>
      )}
    </div>
  );
};

export default UserForm;
