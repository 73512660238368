import { useCallback, useState } from 'react';
import { User } from '@microsoft/microsoft-graph-types';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

async function fetchUserInfo(tenantId: string, userId: string): Promise<any> {
  return authorizedFetch(`${BaseAPIEndpoint}tenants/${tenantId}/users/${userId}`, {
    method: 'GET',
  });
}

export const useUserLicenseDetails = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const getUserLicenseDetails = useCallback(async (tenantId: string, userId: string): Promise<User | any> => {
    try {
      setLoading(true);
      setError(false);
      const response = await fetchUserInfo(tenantId, userId);
      if (response) {
        setLoading(false);
        setError(false);
        return response.data;
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (errorMessage) {
      console.log('Error fetching user license details', errorMessage);
      setLoading(false);
      setError(true);
    }
  }, []);

  return {
    error,
    loading,
    getUserLicenseDetails,
  };
};
