import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

export const checkIfGroupNameExist = async (tenantId: string, groupName: string): Promise<boolean | null> => {
  try {
    const response = await authorizedFetch<boolean>(`${BaseAPIEndpoint}tenants/${tenantId}/Groups/${groupName}/exist`, {
      method: 'GET',
    });
    if (response && response.code !== undefined && response.code < 400) {
      return response.data ? true : false;
    } else {
      console.log('Failed to check if groupName already exists.');
      return null;
    }
  } catch (error) {
    console.log('Failed to check if groupName already exists.', error);
    return null;
  }
};
