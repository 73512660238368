import { FC, useCallback, useMemo } from 'react';
import { Badge, Card, Icon, Table } from '@intility/bifrost-react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { LicenseAssignment, UserLicenseDetail } from '../../../../models';
import ReprocessLicense from '../../../assignmentErrors/components/reprocessLicense/ReprocessLicense';
import formatDate from '../../../../helpers/formatDate/FormatDate';
import styles from './UserCard.module.css';

interface UserCardProps {
  userDetail: UserLicenseDetail;
}

const UserCard: FC<UserCardProps> = ({ userDetail }) => {
  const checkLicenseStatus = useCallback(({ state }: LicenseAssignment) => {
    return state !== 'Active';
  }, []);

  const checkLicensesStatus = useCallback(() => {
    if (userDetail.licenseAssignments.length > 0) {
      const errors = userDetail.licenseAssignments.filter((license) => license.state !== 'Active');
      return errors.length > 0;
    }
  }, [userDetail.licenseAssignments]);

  const showAssignedLicenses = useMemo(() => {
    if (userDetail.licenseAssignments.length > 0) {
      return userDetail.licenseAssignments.map((license, key) => {
        return (
          <Table.Row key={key}>
            <Table.Cell>{license.skuName}</Table.Cell>
            <Table.Cell>{license.groupName ? license.groupName : <Badge>Direct assignment</Badge>}</Table.Cell>
            <Table.Cell>{formatDate(license.lastUpdatedDateTime)}</Table.Cell>
            <Table.Cell>
              {checkLicenseStatus(license) ? (
                license.error
              ) : (
                <Badge state="success">
                  <Icon icon={faCheckCircle} />
                  Active
                </Badge>
              )}
            </Table.Cell>
          </Table.Row>
        );
      });
    } else {
      return null;
    }
  }, [checkLicenseStatus, userDetail.licenseAssignments]);

  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <div className={styles.head}>
          <h3 className={styles.name}>{userDetail.displayName}</h3>
          {checkLicensesStatus() && <ReprocessLicense userId={userDetail.userId} tenantId={userDetail.tenantId} />}
        </div>
        <div className={styles.upn}>
          <Badge style={{ marginRight: 4 }}>UPN</Badge>
          <p style={{ margin: 0 }}>{userDetail.upn}</p>
        </div>
      </div>
      <div>
        <h3 className="bf-h3">License information</h3>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Subscription</Table.HeaderCell>
              <Table.HeaderCell>Azure AD Group</Table.HeaderCell>
              <Table.HeaderCell>Last run</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{showAssignedLicenses}</Table.Body>
        </Table>
      </div>
    </Card>
  );
};
export default UserCard;
