import { Subscription } from 'models';
import { AlsoSubscription } from 'models/AlsoMarketPlace';
import { HandledConnectedSubscription } from 'models/SubscriptionConnection';

/**
 * This function checks and categorizes Also subscriptions into handled and unhandled.
 *
 * @param alsoHandledUncSubs - Array of handled connected Also subscriptions.
 * @param alsoUnconnectedSubscription - Array of unconnected Also subscriptions.
 * @param setUnhandledAlsoSubs - Function to set the unhandled Also subscriptions.
 * @param setHandledAlsoSubs - Function to set the handled Also subscriptions.
 */

export const CheckHandledAlsoSubs = (
  alsoHandledUncSubs: HandledConnectedSubscription[],
  alsoUnconnectedSubscription: AlsoSubscription[],
  setUnhandledAlsoSubs: (subs: AlsoSubscription[]) => void,
  setHandledAlsoSubs: (subs: AlsoSubscription[]) => void,
) => {
  const handledSubscriptionIds = alsoHandledUncSubs.map((sub) => sub.alsoSubscriptionId);

  const handledSubscriptions = alsoUnconnectedSubscription
    .filter((sub) => handledSubscriptionIds.includes(sub.accountId))
    .map((sub) => {
      const handledSub = alsoHandledUncSubs.find((item) => item.alsoSubscriptionId === sub.accountId);

      if (handledSub) {
        return {
          ...sub,
          ...handledSub,
          handledStatus: 'handled',
          accountId: sub.accountId,
          handledId: handledSub.id,
        };
      }
      return null;
    })
    .filter(Boolean);

  const unhandledSubscriptions = alsoUnconnectedSubscription
    .filter((sub) => !handledSubscriptionIds.includes(sub.accountId))
    .map((sub) => {
      const unhandledSub = alsoHandledUncSubs.find((item) => item.alsoSubscriptionId === sub.accountId);

      return {
        ...sub,
        handledStatus: 'unhandled',
        accountId: sub.accountId,
        handledId: unhandledSub ? unhandledSub.id : null,
      };
    });

  setHandledAlsoSubs(handledSubscriptions as AlsoSubscription[]);
  setUnhandledAlsoSubs(unhandledSubscriptions as AlsoSubscription[]);
};

/**
 * This function checks and categorizes subscriptions into handled and unhandled.
 *
 * @param microsoftHandledUncSubs - Array of handled connected Microsoft subscriptions.
 * @param subscriptions - Array of subscriptions to be checked.
 * @param setHandledMicrosoftSubs - Function to set the handled subscriptions.
 * @param setUnhandledMicrosoftSubs - Function to set the unhandled subscriptions.
 */
export const CheckHandledMicrosoftSubs = (
  microsoftHandledUncSubs: HandledConnectedSubscription[],
  subscriptions: Subscription[],
  setHandledMicrosoftSubs: (subs: Subscription[]) => void,
  setUnhandledMicrosoftSubs: (subs: Subscription[]) => void,
) => {
  const handledSubscriptionIds = microsoftHandledUncSubs.map((sub) => sub.microsoftSubscriptionId);

  const handledSubscriptions = subscriptions
    .filter((sub) => handledSubscriptionIds.includes(sub.subscriptionId))
    .map((sub) => {
      const handledSub = microsoftHandledUncSubs.find(
        (handled) => handled.microsoftSubscriptionId === sub.subscriptionId,
      );

      return {
        ...sub,
        status: 'handled',
        handledID: handledSub ? handledSub.id : null,
      };
    });

  const unhandledSubscriptions = subscriptions
    .filter((sub) => !handledSubscriptionIds.includes(sub.subscriptionId))
    .map((sub) => ({
      ...sub,
      status: 'unhandled',
    }));

  setHandledMicrosoftSubs(handledSubscriptions as Subscription[]);
  setUnhandledMicrosoftSubs(unhandledSubscriptions as Subscription[]);
};
