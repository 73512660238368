import { useState } from 'react';
import styles from './TenantConfiguration.module.css';
import { useTenants } from '../../helpers/hooks';
import { useParams } from 'react-router-dom';
import TenantHeader from './components/tenantView/components/TenantHeader';
import TenantView from './components/tenantView/TenantView';
import { AlsoConnection } from 'models/AlsoMarketPlace';
import { BackButton, Button, Drawer } from '@intility/bifrost-react';
import OrderSubscriptionForm from './components/orderSubscriptionForm/OrderSubscriptionForm';

type RouteParams = {
  id: string;
};

export const ConfigureTenantPage = () => {
  const [orderStateCancel, setOrderStateCancel] = useState(false);
  const { id: tenantId } = useParams<RouteParams>();
  const assuredTenantId = tenantId!;
  const [orderSubDrawer, setOrderSubDrawer] = useState(false);
  const [showConnectedMarketplaceModal, setShowConnectedMarketplaceModal] = useState(false);
  const [alsoConnectionLoading, setAlsoConnectionLoading] = useState(false);
  const [alsoConnectionID, setAlsoConnectionID] = useState<AlsoConnection | undefined>();
  const { data: tenants } = useTenants();

  let tenant;

  const checkTenant = async () => {
    if (tenants) {
      tenant = tenants.find((tenant) => tenant.id === assuredTenantId);
    } else {
      tenant = 'Tenant';
    }
  };
  checkTenant();

  return (
    <div className={styles.page}>
      <BackButton />

      <div className={styles.content}>
        <TenantHeader
          tenantDomain={tenant.domain}
          msTenantName={tenant.name}
          msTenantID={assuredTenantId}
          showConnectedMarketplaceModal={showConnectedMarketplaceModal}
          setShowConnectedMarketplaceModal={setShowConnectedMarketplaceModal}
          setOrderSubDrawer={setOrderSubDrawer}
          alsoConnectionID={alsoConnectionID}
          alsoConnectionLoading={alsoConnectionLoading}
          setAlsoConnectionLoading={setAlsoConnectionLoading}
        />

        <TenantView
          setShowConnectedMarketplaceModal={setShowConnectedMarketplaceModal}
          assuredTenantId={assuredTenantId}
          alsoConnectionID={alsoConnectionID}
          setAlsoConnectionID={setAlsoConnectionID}
          alsoConnectionLoading={alsoConnectionLoading}
          setAlsoConnectionLoading={setAlsoConnectionLoading}
        />

        <Drawer
          style={{ padding: '0' }}
          overlay
          className={styles.orderDrawer}
          header="Order new subscription"
          isOpen={orderSubDrawer}
          onRequestClose={() => setOrderSubDrawer(false)}
          footer={
            <div style={{ maxHeight: '20px' }}>
              <Button
                className={styles.orderDrawerCancel}
                onClick={() => {
                  setOrderStateCancel(true);
                }}
              >
                Cancel
              </Button>
            </div>
          }
        >
          <OrderSubscriptionForm
            setOrderSubDrawer={setOrderSubDrawer}
            tenantId={assuredTenantId}
            orderStateCancel={orderStateCancel}
            setOrderStateCancel={setOrderStateCancel}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default ConfigureTenantPage;
