import { FC, useMemo } from 'react';
import { Badge, Button, Icon, Skeleton, Table } from '@intility/bifrost-react';
import { CopyIconButton } from 'components';
import TableFeedback from 'components/common/tableFeedback/TableFeedback';
import SubscriptionCell from '../../../helpers/SubscriptionCell';
import renderTermDuration from 'helpers/utils/RenderTermDuration';
import { Subscription } from 'models';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import formatDate from 'helpers/formatDate/FormatDate';

interface UnconnectedSubscriptionsProps {
  rawSubscriptionData?: Subscription[];
  isLoading?: boolean;
  error?: any;
  setShowUnconnectedManageDrawer: any;
  setSelectedSubscription: any;
  filteredUnhandledData?: any[];
}

export const ShowUnconnectedSubscriptions: FC<UnconnectedSubscriptionsProps> = ({
  rawSubscriptionData,
  isLoading,
  error,
  setShowUnconnectedManageDrawer,
  setSelectedSubscription,
  filteredUnhandledData,
}) => {
  const unconnectedSubscriptions = useMemo(() => {
    const handleManageClick = (config: Subscription) => {
      setShowUnconnectedManageDrawer(true);
      if (config) {
        setSelectedSubscription(config);
      }
    };
    if (filteredUnhandledData && filteredUnhandledData.length > 0 && !isLoading) {
      return filteredUnhandledData.map((config) => (
        <Table.Row key={config.subscriptionId}>
          <Table.Cell>
            <CopyIconButton copyString={config.subscriptionId} />
            <SubscriptionCell
              name={config.name}
              productType={config.productType}
              vendorName={config.vendorName}
              licenseServiceId={config.licenseServiceId}
              partnerStatus={config.partnerStatus}
            />
          </Table.Cell>
          <Table.Cell>{config.unitType}</Table.Cell>
          <Table.Cell>{renderTermDuration(config.termDuration)}</Table.Cell>
          <Table.Cell>{config.billingCycle}</Table.Cell>
          <Table.Cell>
            {formatDate(config.commitmentEndDate)}
            {config.autoRenewEnabled ? <Badge pill>auto</Badge> : ''}
          </Table.Cell>
          <Table.Cell>{config.quantity}</Table.Cell>
          <Table.Cell align="right">
            <Button small variant="outline" onClick={() => handleManageClick(config)}>
              <Icon icon={faCog}></Icon> Manage
            </Button>
          </Table.Cell>
        </Table.Row>
      ));
    }
    if (isLoading) {
      return (
        <Skeleton repeat={2}>
          <Table.Row>
            <Skeleton repeat={6}>
              <Table.Cell>
                <Skeleton.Text />
              </Table.Cell>
            </Skeleton>
          </Table.Row>
        </Skeleton>
      );
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={9}>
            <TableFeedback
              unfilteredData={rawSubscriptionData}
              error={error}
              filteredData={filteredUnhandledData}
              emptyMessage={'No Unconnected subscriptions present'}
            />
          </Table.Cell>
        </Table.Row>
      );
    }
  }, [
    error,
    filteredUnhandledData,
    isLoading,
    rawSubscriptionData,
    setSelectedSubscription,
    setShowUnconnectedManageDrawer,
  ]);

  return <>{unconnectedSubscriptions}</>;
};

export default ShowUnconnectedSubscriptions;
