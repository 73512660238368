import { FC, useCallback, useEffect, useState } from 'react';
import styles from './TenantView.module.css';
import { Tabs } from '@intility/bifrost-react';
import ConfigureMicrosoft from './microsoft/ConfigureMicrosoft';
import ConfigureMarketplace from './marketplace/ConfigureMarketplace';
import ConnectMarketplace from './marketplace/ConnectMarketplace';
import { fetchAlsoConnectionID } from 'api/fetchers/fetchAlsoConnectionID';
import { Subscription } from 'models';
import { MarketProps, MSProps } from 'models/TenantConfigProps';
import { cache } from 'swr/_internal';
import { CheckHandledMicrosoftSubs, CheckHandledAlsoSubs } from '../../components/tenantView/helpers/checkHandled';
import {
  useMicrosoftHandledUnconnectedSubscriptions,
  useAlsoHandledUnconnectedSubscriptions,
  useAlsoConnectedSubscriptions,
  useAlsoSubscriptions,
  useCSPSubscriptions,
  useExternalSubscriptions,
  useExternalSubscriptionsInfo,
  useGetAdobe,
  useGroups,
  useSubscriptionConnection,
  useSubscriptions,
} from '../../../../helpers/hooks';
import { mutate } from 'swr';
import { AlsoConnection, AlsoSubscription } from 'models/AlsoMarketPlace';

interface TenantViewProps {
  setShowConnectedMarketplaceModal: (showConnectMarketplace: boolean) => void;
  assuredTenantId: string;
  alsoConnectionID: AlsoConnection | undefined;
  setAlsoConnectionID: (alsoConnectionID: AlsoConnection | undefined) => void;
  alsoConnectionLoading: boolean;
  setAlsoConnectionLoading: (alsoConnectionLoading: boolean) => void;
}

export const TenantView: FC<TenantViewProps> = ({
  setShowConnectedMarketplaceModal,
  assuredTenantId,
  alsoConnectionID,
  alsoConnectionLoading,
  setAlsoConnectionID,
  setAlsoConnectionLoading,
}) => {
  const [activeTab, setActiveTab] = useState(0); // 0 for Microsoft, 1 for Marketplace

  const [MarketplaceNumber, setMarketplaceNumber] = useState<number>(0);
  const [amountOfConnectedMarketPlace, setAmountOfConnectedMarketPlace] = useState<number>(0);
  const [amountOfUnconnectedMarketPlace, setAmountOfUnconnectedMarketPlace] = useState<number>(0);
  const [MirosoftNumber, setMirosoftNumber] = useState<number>(0);
  const [amountOfConnectedMS, setAmountOfConnectedMS] = useState<number>(0);
  const [amountOfUnhandledUnconnectedMS, setAmountOfUnhandledUnconnectedMS] = useState<number>(0);
  const [amountOfHandledUnconnectedMS, setAmountOfHandledUnconnectedMS] = useState<number>(0);
  const [amountOfUnhandledUnconnectedALSO, setAmountOfUnhandledUnconnectedALSO] = useState<number>(0);
  const [amountOfHandledUnconnectedALSO, setAmountOfHandledUnconnectedALSO] = useState<number>(0);
  const [amountOfDocumentedMS, setAmountOfDocumentedMS] = useState<number>(0);
  const [HandledMicrosoftSubs, setHandledMicrosoftSubs] = useState<Subscription[]>();
  const [UnhandledMicrosoftSubs, setUnhandledMicrosoftSubs] = useState<Subscription[]>();
  const [HandledAlsoSubs, setHandledAlsoSubs] = useState<AlsoSubscription[]>();
  const [UnhandledAlsoSubs, setUnhandledAlsoSubs] = useState<AlsoSubscription[]>();
  const [fetchAlsoDetails, setFetchAlsoDetails] = useState(false);

  const { data: groups } = useGroups(assuredTenantId);
  const { data: externalSubscriptions, error } = useExternalSubscriptions(assuredTenantId);
  const { data: subscriptions, isLoading: isLoadingUnconnectedSubscriptions } = useSubscriptions(assuredTenantId);
  const { data: CSPSubscription, isLoading: isLoadingCSPSubscriptions } = useCSPSubscriptions(assuredTenantId);
  const { data: subscriptionConnection, isLoading: isLoadingConnectedSubscriptions } =
    useSubscriptionConnection(assuredTenantId);
  const { data: externalSubscription, isLoading: isLoadingExternalSubscriptions } =
    useExternalSubscriptionsInfo(assuredTenantId);
  const { data: alsoUnconnectedSubscription, isLoading: isLoadingAlsoUnconnectedSubscription } = useAlsoSubscriptions(
    alsoConnectionID?.alsoCompanyAccountId,
  );
  const {
    data: alsoHandledUncSubs,
    isLoading: isLoadingAlsoHandledUnconnectedSubscriptions,
    error: ErrorsalsoHandledUncSubs,
  } = useAlsoHandledUnconnectedSubscriptions(assuredTenantId);
  const { data: microsoftHandledUncSubs, isLoading: isLoadingMicrosoftHandledUnconnectedSubscriptions } =
    useMicrosoftHandledUnconnectedSubscriptions(assuredTenantId);
  const { data: adobeGroups, isLoading: isLoadingAdobe, mutate: mutateAdobe } = useGetAdobe(assuredTenantId);
  const { data: alsoConnectedSubscription, isLoading: isLoadingAlsoConnectedSubscription } =
    useAlsoConnectedSubscriptions(assuredTenantId, fetchAlsoDetails);

  useEffect(() => {
    const fetchData = async () => {
      cache.delete('subscriptions');

      await Promise.all([
        mutate('groups'),
        mutate('subscriptions'),
        mutate('CSPSubscriptions'),
        mutate('SubscriptionConnection'),
        mutate('externalSubscriptions'),
        mutate('externalSubscriptionsInfo'),
        mutate('ExternalSubscriptionInfoDto'),
        mutate('microsoftHandledUncSubs'),
      ]);
    };

    fetchData();
    setMarketplaceNumber(0);
    setAmountOfConnectedMarketPlace(0);
    setAmountOfUnconnectedMarketPlace(0);
    setMirosoftNumber(0);
    setAmountOfConnectedMS(0);
    setAmountOfUnhandledUnconnectedMS(0);
    setAmountOfHandledUnconnectedMS(0);
    setAmountOfUnhandledUnconnectedALSO(0);
    setAmountOfHandledUnconnectedALSO(0);
    setAmountOfDocumentedMS(0);
  }, [assuredTenantId]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        mutate('alsoSubscriptions'),
        mutate('alsoConnectedSubscriptions'),
        mutate('alsoHandledUncSubs'),
      ]);
    };

    if (alsoConnectionID) {
      fetchData();
    }
  }, [alsoConnectionID]);

  useEffect(() => {
    mutateAdobe();
  }, [mutateAdobe]);

  useEffect(() => {
    cache.delete('subscriptions');
    mutate('subscriptions');
    cache.delete('CSPSubscriptions');
    mutate('CSPSubscriptions');
    cache.delete('SubscriptionConnection');
    mutate('SubscriptionConnection');
    cache.delete('externalSubscriptions');
    mutate('externalSubscriptions');
    cache.delete('externalSubscriptionsInfo');
    mutate('externalSubscriptionsInfo');
    cache.delete('ExternalSubscriptionInfoDto');
    mutate('ExternalSubscriptionInfoDto');
    cache.delete('microsoftHandledUncSubs');
    mutate('microsoftHandledUncSubs');
    setMarketplaceNumber(0);
    setAmountOfConnectedMarketPlace(0);
    setAmountOfUnconnectedMarketPlace(0);
    setMirosoftNumber(0);
    setAmountOfConnectedMS(0);
    setAmountOfUnhandledUnconnectedMS(0);
    setAmountOfHandledUnconnectedMS(0);
    setAmountOfUnhandledUnconnectedALSO(0);
    setAmountOfHandledUnconnectedALSO(0);
    setAmountOfDocumentedMS(0);
  }, [assuredTenantId]);

  useEffect(() => {
    if (assuredTenantId) {
      const checkAlsoConnection = async () => {
        setAlsoConnectionLoading(true);
        try {
          const response = await fetchAlsoConnectionID(assuredTenantId);
          if (response.data !== null && response.data !== undefined && response.code !== 204) {
            setAlsoConnectionID(response.data);
            setFetchAlsoDetails(true);
          } else {
            setAlsoConnectionID(undefined);
            setFetchAlsoDetails(false);
          }
        } catch (error) {
          console.error('Error fetching Also Connection ID', error);
          setAlsoConnectionID(undefined);
        }
        setAlsoConnectionLoading(false);
      };

      checkAlsoConnection();
    }
  }, [assuredTenantId, setAlsoConnectionID, setAlsoConnectionLoading]);

  useEffect(() => {
    if (alsoUnconnectedSubscription && alsoHandledUncSubs) {
      CheckHandledAlsoSubs(alsoHandledUncSubs, alsoUnconnectedSubscription, setUnhandledAlsoSubs, setHandledAlsoSubs);
    }
    if (subscriptions && microsoftHandledUncSubs) {
      CheckHandledMicrosoftSubs(
        microsoftHandledUncSubs,
        subscriptions,
        setHandledMicrosoftSubs,
        setUnhandledMicrosoftSubs,
      );
    }
  }, [alsoHandledUncSubs, microsoftHandledUncSubs, subscriptions, alsoUnconnectedSubscription]);

  const handleMSMutate = useCallback(() => {
    mutate('subscriptions');
    mutate('CSPSubscriptions');
    mutate('SubscriptionConnection');
    mutate('groups');
    mutate('externalSubscriptions');
    mutate('externalSubscriptionsInfo');
    mutate('microsoftHandledUncSubs');
  }, []);

  const handleMarketMutate = useCallback(() => {
    mutate('groups');
    mutate('alsoConnectionID');
    mutate('alsoSubscriptions');
    mutate('alsoConnectedSubscriptions');
    mutate('alsoHandledUncSubs');
  }, []);

  const renderGroupName = useCallback(
    (groupId) => {
      if (externalSubscription) {
        const group = externalSubscription.groups.find((_group) => _group.id === groupId);
        if (group) {
          return group.displayName;
        } else {
          return ' - Not connected -';
        }
      }
    },
    [externalSubscription],
  );

  const MSProps: MSProps = {
    externalSubscriptions,
    subscriptionConnections: subscriptionConnection,
    subscriptions,
    renderGroupName,
    isLoadingUnconnectedSubscriptions,
    isLoadingConnectedSubscriptions,
    isLoadingExternalSubscriptions,
    error,
    assuredTenantId,
    groups: groups || [],
    externalSubscription,
    CSPSubscription,
    isLoadingCSPSubscriptions,
    handleMSMutate,
    MirosoftNumber,
    setMirosoftNumber,
    amountOfConnectedMS,
    setAmountOfConnectedMS,
    amountOfUnhandledUnconnectedMS,
    setAmountOfUnhandledUnconnectedMS,
    amountOfHandledUnconnectedMS,
    setAmountOfHandledUnconnectedMS,
    amountOfDocumentedMS,
    setAmountOfDocumentedMS,
    HandledMicrosoftSubs,
    UnhandledMicrosoftSubs,
    adobeGroups,
    isLoadingAdobe,
    isLoadingMicrosoftHandledUnconnectedSubscriptions,
    ErrorsalsoHandledUncSubs,
  };

  const MarketProps: MarketProps = {
    groups: groups || [],
    assuredTenantId,
    //Marketplace
    alsoConnectionID,
    alsoConnectionLoading,
    setAlsoConnectionLoading,
    alsoUnconnectedSubscription,
    isLoadingAlsoUnconnectedSubscription,
    alsoConnectedSubscription,
    isLoadingAlsoConnectedSubscription,
    handleMarketMutate,
    MarketplaceNumber,
    setMarketplaceNumber,
    amountOfConnectedMarketPlace,
    setAmountOfConnectedMarketPlace,
    amountOfUnconnectedMarketPlace,
    setAmountOfUnconnectedMarketPlace,
    amountOfHandledUnconnectedALSO,
    setAmountOfHandledUnconnectedALSO,
    amountOfUnhandledUnconnectedALSO,
    setAmountOfUnhandledUnconnectedALSO,
    HandledAlsoSubs,
    UnhandledAlsoSubs,
    isLoadingAlsoHandledUnconnectedSubscriptions,
  };

  useEffect(() => {
    if (subscriptionConnection) {
      setAmountOfConnectedMS(subscriptionConnection.length);
    }
    if (UnhandledMicrosoftSubs) {
      setAmountOfUnhandledUnconnectedMS(UnhandledMicrosoftSubs.length);
    }
    if (HandledMicrosoftSubs) {
      setAmountOfHandledUnconnectedMS(HandledMicrosoftSubs.length);
    }
    if (externalSubscriptions) {
      setAmountOfDocumentedMS(externalSubscriptions.length);
    }
  }, [
    externalSubscriptions,
    UnhandledMicrosoftSubs,
    HandledMicrosoftSubs,
    setAmountOfConnectedMS,
    setAmountOfUnhandledUnconnectedMS,
    setAmountOfHandledUnconnectedMS,
    setAmountOfDocumentedMS,
    subscriptionConnection,
  ]);

  useEffect(() => {
    if (alsoConnectedSubscription) {
      setAmountOfConnectedMarketPlace(alsoConnectedSubscription.length);
    }
    if (alsoUnconnectedSubscription) {
      setAmountOfUnhandledUnconnectedALSO(alsoUnconnectedSubscription.length);
    }
    if (HandledAlsoSubs) {
      setAmountOfHandledUnconnectedALSO(HandledAlsoSubs.length);
    }
  }, [
    setAmountOfConnectedMarketPlace,
    setAmountOfHandledUnconnectedALSO,
    alsoConnectedSubscription,
    alsoUnconnectedSubscription,
    HandledAlsoSubs,
    setAmountOfUnhandledUnconnectedALSO,
  ]);

  useEffect(() => {
    setMirosoftNumber(amountOfUnhandledUnconnectedMS);
  }, [setMirosoftNumber, amountOfUnhandledUnconnectedMS]);

  useEffect(() => {
    setMarketplaceNumber(amountOfUnhandledUnconnectedALSO);
  }, [setMarketplaceNumber, amountOfUnhandledUnconnectedALSO]);

  useEffect(() => {
    setAmountOfUnconnectedMarketPlace(UnhandledAlsoSubs?.length || 0);
  }, [setAmountOfUnconnectedMarketPlace, alsoUnconnectedSubscription, UnhandledAlsoSubs]);

  useEffect(() => {
    setAmountOfConnectedMarketPlace(alsoConnectedSubscription?.length || 0);
  }, [setAmountOfConnectedMarketPlace, alsoConnectedSubscription]);

  useEffect(() => {
    if (!alsoConnectionID) {
      setMarketplaceNumber(0);
    }
  }, [setMarketplaceNumber, alsoConnectionID]);

  return (
    <Tabs variant="styled" className={styles.tabStyle}>
      <Tabs.Item
        active={activeTab === 0}
        onClick={() => setActiveTab(0)}
        style={{ background: activeTab === 0 ? 'var(--bfc-base-2)' : 'transparent' }}
        className={`${styles.tabItemStyle} ${activeTab === 0 ? styles.activeTab : ''}`}
        noPadding
        content={
          <div className={styles.tabsContent}>
            <ConfigureMicrosoft MSProps={MSProps} />
          </div>
        }
      >
        <span>
          Microsoft (<span className={styles.numberStyle}>{MirosoftNumber}</span>)
        </span>
      </Tabs.Item>
      <Tabs.Item
        active={activeTab === 1}
        onClick={() => setActiveTab(1)}
        style={{ background: activeTab === 1 ? 'var(--bfc-base-2)' : 'transparent' }}
        className={`${styles.tabItemStyle} ${activeTab === 1 ? styles.activeTab : ''}`}
        noPadding
        content={
          <div className={styles.tabsContent}>
            {alsoConnectionID && <ConfigureMarketplace MarketProps={MarketProps} MSProps={MSProps} />}
            {!alsoConnectionID && !alsoConnectionLoading && (
              <ConnectMarketplace setShowConnectedMarketplaceModal={setShowConnectedMarketplaceModal} />
            )}
          </div>
        }
      >
        <span>
          Marketplace (<span className={styles.numberStyle}>{MarketplaceNumber}</span>)
        </span>
      </Tabs.Item>
    </Tabs>
  );
};

export default TenantView;
