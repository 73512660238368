import { useTenants } from 'helpers/hooks';
import styles from './Tenants.module.css';
import TenantListItem from './components/TenantListItem';
import TenantsDropDown from './components/TenantsDropDown';
import { FC, useState } from 'react';

const Tenants: FC = () => {
  const { data: tenants = [], isLoading } = useTenants();
  const [selectedTenant, setSelectedTenant] = useState<{ label: string; value: string; domain: string } | null>(null);
  const [recentTenants, setRecentTenants] = useState<{ label: string; value: string; domain: string }[]>([]);

  return (
    <div className={styles.page} style={{ height: '100%', width: '100%' }}>
      <div className={styles.contentsContainer}>
        <h1 className={styles.header + ' bf-h1'}>Choose tenant</h1>
        <TenantsDropDown
          tenants={tenants}
          isLoading={isLoading}
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
          setRecentTenants={setRecentTenants}
        />
        {recentTenants.length > 0 && (
          <span className={styles.cardDescription + ' bf-p bf-medium bf-strong'}>Previously visited</span>
        )}
        <TenantListItem viewed={recentTenants} />
      </div>
      <div className={styles.ellipseSmall} />
      <div className={styles.ellipseMedium} />
      <div className={styles.ellipseLarge} />
    </div>
  );
};

export default Tenants;
