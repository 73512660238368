import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from './authConfig';

export interface APIResponse<T> {
  code?: number;
  message?: string;
  headers?: Headers;
  data?: T;
}

export const authorizedFetch = async <T>(url: string, init?: RequestInit): Promise<APIResponse<T>> => {
  let initWithToken = init;
  /* eslint-disable */
  try {
    const token = await msalInstance
      .acquireTokenSilent({ scopes: [`${import.meta.env['VITE_GATEWAY_URI']}`] })
      .catch(async (error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          return await msalInstance.acquireTokenRedirect({ scopes: [`${import.meta.env['VITE_GATEWAY_URI']}`] });
        }
        console.log(error);
        return null;
      });

    initWithToken = {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: 'Bearer ' + token?.accessToken,
      },
    };
    let data: T = {} as T;

    const response = await fetch(url, initWithToken);
    if (!response.ok) {
      try {
        data = await response.json();
      } catch (error) {
        return {
          code: response.status,
          message: response.statusText,
          headers: response.headers,
        };
      }

      throw {
        status: 'ERROR',
        code: response.status,
        message: response.statusText,
        data: data,
      };
    }

    try {
      data = await response.json();
    } catch (e) {
      console.log('No content');
    }

    return {
      code: response.status,
      message: response.statusText,
      headers: response.headers,
      data: data,
    };
  } catch (e) {
    throw e;
  }
};
