import { Checkbox } from '@intility/bifrost-react';

const MarkAsHandled = ({ handled, setHandled, setHandledChanged, isLoading, loading }) => {
  return (
    <>
      <p className="bf-medium" style={{ marginBottom: 0 }}>
        Check or uncheck to move the subscription to the handled or unhandled list accordingly.
      </p>
      <Checkbox
        button
        style={{ marginTop: 16, marginBottom: 8 }}
        label="Handled"
        type="checkbox"
        checked={handled}
        onChange={() => {
          setHandled((prevState) => !prevState);
          setHandledChanged(true);
        }}
        disabled={isLoading || loading}
      />
    </>
  );
};

export default MarkAsHandled;
